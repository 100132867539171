import { H1, PopoverPosition } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import SingleSelect from 'components/elements/filter/SingleSelect';
import {
    Icon,
    ItemCount,
    LoadingIndicator,
    Paginator,
    Reload,
    Table,
} from 'components/elements/index';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { convertToFlatObject, globalDebounce } from 'helpers/helpers';
import { emptyListData } from 'interface';
import { ListFilter, ListProps, ListSearchFilter } from 'interface/List';
import { isEqual } from 'lodash';
import { Location, Routing } from 'service';
import Text from './filter/Text';
import TypeAhead from './filter/TypeAhead';

(window as any).listParams = {};
const List = <T extends ListFilter, Type>(props: ListProps<T, Type>) => {
    const {
        title,
        load,
        preLoadList = true,
        delayedLoad,
        defaultFilter = {},
        columns,
        filter,
        isLoading,
        location,
        headerButton,
        iconButtons,
        noRecordsFoundText,
        description,
        history,
        downloadHandler,
        hideFilters,
        preIsFilterOpen = false,
        subHeader,
        selectAll,
        onChange,
        onClear,
    } = props;

    const getTypeaheadKeys = () => {
        return (
            props.filters &&
            Object.values(props.filters)
                .filter((row: any) => row.type === 'typeahead')
                .map((row: any) => row.key)
        );
    };

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(preIsFilterOpen);
    const [filterData, setFilterData] = useState<any>();
    const [searchFilters, setSearchFilters] = useState<any>({});
    const [optionsIsLoading, setOptionsIsLoading] = useState<any>(true);
    const [searchFilterTypeAheadsKeys, setSearchFilterTypeAheadsKeys] =
        useState<any[]>();
    const [collection, setCollection] = useState<any>(emptyListData);
    const loadingPageUrl = history.location.pathname;

    const clearFilters = () => {
        setFilterData(defaultFilter);
        setIsFilterOpen(preIsFilterOpen);
        onFilter(defaultFilter as T);
        typeof onClear === 'function' && onClear();
    };

    const tableSubHeader = {
        subHeader: !!subHeader,
        subHeaderComponent: subHeader,
    };

    const onFilter = async (filter: any, page = 1) => {
        delete filter.modifiers;
        // add typeahead objects to the URL filter
        const waitForLoopToFinish = Object.keys(filter).map((key: any) => {
            if (searchFilterTypeAheadsKeys?.includes(key)) {
                filter[key + '-object'] = JSON.stringify(
                    convertToFlatObject(searchFilters[key].defaultValue)
                );
            }
            return key;
        });
        await Promise.all(waitForLoopToFinish);
        filter.page = page;

        Location.setQueryStringFromObject(history, location, filter, true);
    };

    const getFilters = () => {
        return Object.keys(searchFilters).map((key: string) => {
            if (typeof key === undefined) {
                return false;
            }

            const filter: ListSearchFilter = searchFilters[key];
            // add more types in the future
            switch (filter.type) {
                case 'single-select':
                    return (
                        <SingleSelect
                            key={key}
                            defaultValue={filterData[filter.key]}
                            label={filter.label}
                            filterKey={filter.key}
                            options={filter.options}
                        ></SingleSelect>
                    );
                case 'text':
                    return (
                        <Text
                            key={key}
                            value={filterData[key]}
                            filterKey={filter.key}
                            label={filter.label}
                            name={filter.key}
                            placeholder={filter.placeholder}
                        />
                    );
                case 'typeahead':
                    return (
                        <TypeAhead
                            key={filter.key}
                            filterKey={filter.key}
                            label={filter.label}
                            url={filter.url}
                            searchParams={filter.searchParams}
                            responseProperty={filter.responseProperty}
                            valueProperty={filter.valueProperty}
                            searchProperty={filter.searchProperty}
                            onSelect={filter.onSelect}
                            defaultValue={filter.defaultValue}
                        />
                    );
                default:
                    return <div key={key} />;
            }
        });
    };

    useEffect(() => {
        if (!delayedLoad) {
            return;
        }
        // load initial URL params
        const initialParams: any = Routing.parseSearchParams(location.search);
        (window as any).listParams = initialParams;
        load({ ...defaultFilter, ...initialParams });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedLoad]);

    useEffect(() => {
        // load initial URL params when page loads
        if (preLoadList) {
            const preloadParams: any = Routing.parseSearchParams(
                location.search
            );
            (window as any).listParams = preloadParams;
            load({ ...defaultFilter, ...preloadParams });
        }

        // listen function returns a unsubscribe function that stops the listening.
        const unsubscribe = history.listen(async (location) => {
            if (loadingPageUrl !== history.location.pathname) {
                return;
            }
            const newParams: any = Routing.parseSearchParams(location.search);
            if (isEqual((window as any).listParams, newParams)) {
                return;
            }
            (window as any).listParams = { ...newParams };
            globalDebounce(
                () => {
                    load(newParams);
                },
                'ListParamLoad',
                500,
                true
            );
        });

        // stop listening when component is un-mounted
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.filters) {
            return;
        }
        (async () => {
            const filters = props.filters;
            // decode typeahead object from URL if it exists
            const waitForLoopToFinish = Object.keys(filters)
                .map((key: string) => {
                    const filter: any = props.filters[key];
                    let paramFilter: any;
                    if (
                        filter.key in (window as any).listParams &&
                        filter.key + '-object' in (window as any).listParams
                    ) {
                        try {
                            paramFilter = JSON.parse(
                                decodeURIComponent(
                                    (window as any).listParams[
                                        filter.key + '-object'
                                    ]
                                )
                            );
                        } catch (error) {
                            console.error(
                                'could not read params correctly',
                                (window as any).listParams
                            );
                        }
                    }
                    filter.defaultValue = filter.defaultValue ?? paramFilter;
                    filters[key] = filter;

                    return filter;
                })
                // if options are not loaded yet, add it to the list
                .filter((filter) => filter.options?.length === 0);
            await Promise.all(waitForLoopToFinish);
            setSearchFilters(filters);
            //only when all options for typeaheads are loaded, render all the filters (this is a negative, optionsIsLoading=true means continue waiting)
            setOptionsIsLoading(waitForLoopToFinish.length > 0);
            setSearchFilterTypeAheadsKeys(getTypeaheadKeys());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filters]);

    useEffect(() => {
        const filterCopy = { ...props.filter } as any;
        Object.keys(filterCopy).map((key: string) => {
            let paramValue: any;
            if (key in (window as any).listParams) {
                paramValue = (window as any).listParams[key];
            }
            filterCopy[key] = filterCopy[key] ?? paramValue;

            return filter;
        });
        setFilterData(filterCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter]);

    useEffect(() => {
        setCollection(props.collection);
    }, [props.collection]);

    return (
        <Loading isLoading={isLoading}>
            <H1>
                {title}
                {isLoading ? (
                    <LoadingIndicator inline={true} size="sm" />
                ) : (
                    <ItemCount count={collection.count} />
                )}

                <Reload load={() => load(filter)} />

                {hideFilters !== true && (
                    <Tooltip2
                        content="Filters"
                        position={PopoverPosition.TOP}
                        className="ms-2"
                    >
                        <button
                            className={`clear-button ${
                                isFilterOpen && 'success'
                            }`}
                            onClick={() => {
                                setIsFilterOpen(!isFilterOpen);
                            }}
                        >
                            <Icon icon="filter" className="icon" />
                        </button>
                    </Tooltip2>
                )}

                {downloadHandler && (
                    <Tooltip2
                        content="Download"
                        position={PopoverPosition.TOP}
                        className="ms-2"
                    >
                        <button
                            className="clear-button"
                            onClick={() => {
                                downloadHandler(filterData);
                            }}
                        >
                            <Icon icon="download" className="icon" />
                        </button>
                    </Tooltip2>
                )}
                {iconButtons ? iconButtons() : null}

                {headerButton ? headerButton() : null}
            </H1>
            {searchFilters && !optionsIsLoading && (
                <>
                    <Filter
                        onClear={clearFilters}
                        onFilter={(filter: T) => onFilter(filter)}
                        filterData={filterData}
                        isOpen={isFilterOpen}
                    >
                        {getFilters()}
                    </Filter>
                </>
            )}
            {description && <p>{description}</p>}
            <NoResultsFound
                count={collection.count}
                label={noRecordsFoundText ?? 'No Results Found'}
            >
                <Table
                    data={collection.data}
                    collection={collection}
                    columns={columns}
                    onSort={(filters: T) => onFilter({ ...filter, ...filters })}
                    onChange={onChange}
                    ordering={filter.order}
                    filter={filterData}
                    selectAll={selectAll}
                    {...tableSubHeader}
                />
                {collection.count > collection.limit && (
                    <Paginator
                        page={filter.page}
                        count={collection.count}
                        limit={filter.limit}
                        onPage={(filters: T) => onFilter(filter, filters.page)}
                    />
                )}
            </NoResultsFound>
        </Loading>
    );
};

export default withRouter(List);
