import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import ClientList from 'components/clients/ClientList';
import ClientAddEdit from 'components/clients/ClientAddEdit';
import ClientDetails from 'components/clients/ClientDetails';

import ProtectedRoute from 'components/elements/ProtectedRoute';
import Campaigns from './Campaigns';

class Clients extends Component {
    render() {
        return (
            <div className="Clients">
                <Switch>
                    <ProtectedRoute
                        path="/clients/list"
                        component={ClientList}
                        exact
                    />
                    <ProtectedRoute
                        path="/clients/campaigns"
                        component={Campaigns}
                    />
                    <ProtectedRoute
                        path="/clients/list/add"
                        component={ClientAddEdit}
                        exact
                    />
                    <ProtectedRoute
                        path="/clients/list/:clientId/edit"
                        component={ClientAddEdit}
                    />
                    <ProtectedRoute
                        path="/clients/list/:clientId"
                        component={ClientDetails}
                    />
                    <Redirect to="/clients/list?order=name" />
                </Switch>
            </div>
        );
    }
}

export default Clients;
