import { ChangeEvent, ComponentProps } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    ButtonGroup,
    FormGroup,
    Intent,
    TextArea,
} from '@blueprintjs/core';
import moment from 'moment';

import {
    AnswerData,
    AssessmentStateData,
    QuestionData,
    ReviewData,
} from 'interface';
import { InputElementIcon, SuggestedComment } from 'components/elements';
import { setReviewChanges } from 'action';
import { checkIsReviewDisabled } from 'service';
import { isAssessorReviewer } from 'helpers/helpers';

interface QuestionReviewProps extends ComponentProps<any> {
    question: QuestionData;
    answer: AnswerData;
    mode: string;
}

const QuestionReview = (props: QuestionReviewProps) => {
    const { question, answer, mode } = props;
    const {
        reviewChanges,
        reviewsCollection,
        stockResponsesCollection,
    }: AssessmentStateData = useSelector((state: any) => state.assessments);

    const dispatch = useDispatch();
    const isReviewsDisabled = checkIsReviewDisabled();

    if (!answer || !answer._links.self) {
        return <div className="flex-50" />;
    }

    const answerReview = reviewChanges.find(
        (review: any) => review._links.answer.href === answer._links.self.href
    ) as ReviewData;

    if (isReviewsDisabled && !answerReview.id && !isAssessorReviewer()) {
        return <div className="flex-50" />;
    }

    const updateReview = (
        answer: AnswerData,
        prop: keyof ReviewData,
        value: any
    ) => {
        const updatedReviewChanges: any[] = [...reviewChanges];

        const reviewIndex = updatedReviewChanges.findIndex(
            (review: ReviewData) => {
                return review._links.answer.href === answer._links.self.href;
            }
        );
        updatedReviewChanges[reviewIndex][prop] = value;

        dispatch(setReviewChanges(updatedReviewChanges));
    };

    const label =
        mode === 'review' && question.assessorGuidanceNotes
            ? InputElementIcon(question.assessorGuidanceNotes, 'info')
            : [];

    const historicalReviews = reviewsCollection.reviews
        .filter((review) => {
            return review._links.answer.href === answer._links.self.href;
        })
        .map((review) => {
            const reviewDate = moment(review.createdAt).format('DD/MM/YY');
            const reviewText =
                (review.isApproved ? 'Pass' : 'Pending') +
                (review.comment ? `- ${review.comment}` : '');

            let userDetails = '-';
            if (review._links.user) {
                const user = `${review._links.user.forename} ${review._links.user.surname}`;
                const telephone = review._links.user.phone
                    ? `-  ${review._links.user.phone}`
                    : '';
                userDetails = `${user}${telephone}`;
            }

            return (
                <div key={review.id} className="mb-2">
                    {reviewDate} {userDetails} {reviewText}
                </div>
            );
        });

    const responseComment = !isReviewsDisabled ? (
        // @ts-ignore SuggestedComment is a highly customised React Component class that PHPStorm doesn't recognise as a React element
        <SuggestedComment
            getItemValue={(item: any) => item.label}
            items={stockResponsesCollection[question.id] ?? []}
            value={answerReview?.comment}
            onChange={(e: ChangeEvent) =>
                updateReview(
                    answer,
                    'comment',
                    (e.target as HTMLInputElement).value
                )
            }
            onSelect={(val: any) => updateReview(answer, 'comment', val)}
        />
    ) : (
        <div className="px-2 full-width">
            <TextArea className="suggested-comment" disabled />
        </div>
    );

    return (
        <div className="flex-50">
            <FormGroup
                inline={true}
                label={label}
                className="form-fill multi-inputs review-form"
            >
                <ButtonGroup vertical className="review-buttons mt-1">
                    <Button
                        key={`question_${question.id}_pass`}
                        type="button"
                        intent={
                            answerReview?.isApproved === true
                                ? Intent.SUCCESS
                                : Intent.NONE
                        }
                        onClick={(e) =>
                            updateReview(answer, 'isApproved', true)
                        }
                        disabled={isReviewsDisabled}
                        className={
                            answerReview?.isApproved === true ? 'active' : ''
                        }
                    >
                        Pass
                    </Button>
                    <Button
                        key={`question_${question.id}_fail`}
                        type="button"
                        intent={
                            answerReview?.isApproved === false
                                ? Intent.WARNING
                                : Intent.NONE
                        }
                        onClick={(e) =>
                            updateReview(answer, 'isApproved', false)
                        }
                        disabled={isReviewsDisabled}
                        className={
                            answerReview?.isApproved === false ? 'active' : ''
                        }
                    >
                        Pending
                    </Button>
                </ButtonGroup>
                {responseComment}
            </FormGroup>

            {historicalReviews.length > 0 && (
                <div className="mx-5 mb-3">
                    <h3>Feedback</h3>
                    {historicalReviews}
                </div>
            )}
        </div>
    );
};

export default QuestionReview;
