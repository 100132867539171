import { H1 } from '@blueprintjs/core';
import {
    ButtonLink,
    Icon,
    ItemCount,
    Paginator,
    Reload,
    Table,
} from 'components/elements';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import { CLIENT_ROLE_OPTIONS } from 'constants/role';
import queryString from 'query-string';
import { Component } from 'react';
import { toast } from 'react-toastify';
import { ClientService, Filters, Location, Response } from 'service';

class UserList extends Component {
    defaultFilters = {
        order: 'forename',
    };

    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            filterData: Filters.getFilterStateFromUrl(
                this.props.location.search.slice(1),
                props.filterData
            ),
        };

        this.load = this.load.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.getRoute = this.getRoute.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const collection = this.state.collectionResponse || {};
        const { isLoading } = this.state;
        const { match, isClientBio } = this.props;

        let columns = [
            {
                name: 'Forename',
                property: 'forename',
                sortable: true,
                grow: 2,
            },
            { name: 'Surname', property: 'surname', sortable: true, grow: 2 },
            { name: 'Role', property: 'displayRole', sortable: false, grow: 2 },
            {
                name: 'Email',
                property: 'email',
                sortable: true,
                grow: 3,
                // hide: 'sm',
            },
            {
                name: 'Is Active',
                property: 'isActive',
                type: 'mapping',
                mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Last Login Date',
                property: 'lastSuccessfulLogin',
                type: 'datetime',
                sortable: false,
                grow: 3,
            },
        ];

        if (!isClientBio) {
            columns.push({
                name: '',
                property: '',
                type: 'nav-button',
                grow: 1,
                route: this.getRoute,
                buttonIntent: 'primary',
                buttonText: 'Edit',
                right: true,
            });
        }

        return (
            <div className="UserList">
                <H1>
                    User List
                    <ItemCount count={collection.count} />
                    <Reload load={this.load} />
                    {Response.getLink(collection, 'create') === null ? null : (
                        <ButtonLink
                            type="button"
                            to={`${match.url}/add`}
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="plus-circle" />
                            Add new User
                        </ButtonLink>
                    )}
                </H1>

                <Filter
                    onClear={this.clearFilters}
                    onFilter={(filters, filterData) =>
                        this.onFilter(filters, filterData)
                    }
                    filterData={{}}
                >
                    <Filter.Text label="Forename" filterKey="forename" />
                    <Filter.Text label="Surname" filterKey="surname" />
                    <Filter.Text label="Email Address" filterKey="email" />
                    <Filter.SingleSelect
                        label="Status"
                        filterKey="isActive"
                        options={ACTIVE_OPTIONS_WITH_NONE_SELECTED}
                    />
                </Filter>

                <Loading isLoading={isLoading}>
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.users}
                            columns={columns}
                            onSort={this.load}
                            ordering={this.state.filter.order}
                        />

                        <Paginator
                            page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.load}
                        />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    getRoute(row, column) {
        return (
            Response.getLink(row, 'edit') && `${this.props.match.url}/${row.id}`
        );
    }

    clearFilters() {
        this.setState({ filterData: {} });
        this.onFilter({}, {});
    }

    onFilter(filters, filterData) {
        this.load(
            Object.assign({}, this.defaultFilters, filterData, { page: 1 }),
            true
        );
    }

    load(params = {}, paramsOverride = false) {
        if (this.state.isLoading) {
            return;
        }

        const { clientResponse, history, location } = this.props;
        const { filter } = this.state;

        const filterParams = paramsOverride
            ? params
            : Object.assign({}, this.defaultFilters, filter, params);
        Location.setQueryStringFromObject(history, location, filterParams);

        this.setState({ filter: filterParams, isLoading: true });

        ClientService.loadClientUsers(clientResponse.id, filterParams).then(
            (response) => {
                if (response) {
                    response.users.forEach((user) => {
                        let selectedRole = user.roles.filter(
                            (role) => role !== 'ROLE_USER'
                        )[0];
                        user.displayRole = CLIENT_ROLE_OPTIONS.filter(
                            (role) => role.value === selectedRole
                        )[0].label;
                    });
                    this.setState({
                        collectionResponse: response,
                        isLoading: false,
                    });

                    return true;
                }

                toast.error('Unable to fetch Users');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }
}

export default UserList;
