import React from 'react';
import { H1 } from '@blueprintjs/core';
import "./styles.scss";

import {
    ItemCount,
    LoadingIndicator,
    Reload,
    Table,
} from 'components/elements';
import { NoResultsFound } from 'components/elements/wrappers';

interface AssessmentTypeTableProps {
    title: string;
    isLoading: boolean;
    count: number;
    data: object[];
    columns: object;
    onSort?: Function;
    onReload?: Function;
    ordering?: object;
    headerContent?: React.ReactNode,
}

const AssessmentTypeInstanceTable = (props: AssessmentTypeTableProps) => {
    const {
        title,
        isLoading,
        count,
        onReload,
        data,
        columns,
        onSort,
        ordering,
        headerContent,
    } = props;

    return (
        <div className='assessment-table'>
            <H1>
                {title}
                {isLoading ? (
                    <LoadingIndicator inline size="sm" />
                ) : (
                    <ItemCount count={count} />
                )}
                <Reload load={onReload} />
            </H1>
            {headerContent}
            <NoResultsFound count={count}>
                <Table
                    data={data}
                    columns={columns}
                    onSort={onSort}
                    ordering={ordering}
                />
            </NoResultsFound>
        </div>
    );
};

export default AssessmentTypeInstanceTable;