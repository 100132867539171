import { Button, Card, H2 } from '@blueprintjs/core';
import { groupBy, merge } from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { toast } from 'react-toastify';

import Address from 'components/clients/Address';
import { MissingInformation } from 'components/company';
import RedFlagSection from 'components/company/RedFlagSection';
import CompanyInfo from 'components/company/info/CompanyInfo';
import AllCompanyUpdates from 'components/dialogs/AllCompanyUpdates';
import { Tel } from 'components/elements';
import ClientRequirements from 'components/elements/company/client-requirement/ClientRequirements';
import {
    isAdmin,
    isCompanyAdmin,
    parseInviteeStatusText,
} from 'helpers/helpers';
import { CampaignService, Filters, HTTP, Response } from 'service';

import './styles.scss';

class Info extends Component {
    static propTypes = {
        companyResponse: PropTypes.object.isRequired,
        reloadData: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            creditHealthCheck: {
                rfaRating: undefined,
                metadata: undefined,
            },
            hasCreditInfo: false,
            showCampaignUpdates: false,
            childUpdates: {},
        };

        this.assessmentsAndClientRequirements =
            this.assessmentsAndClientRequirements.bind(this);
        this.campaignUpdates = this.campaignUpdates.bind(this);
    }

    componentDidMount() {
        const { inviteeStatuses } = this.props.companyResponse;
        const parentIds = [];

        if (inviteeStatuses?.length) {
            inviteeStatuses.forEach((status) => {
                parentIds.push(status.id);
            });
        }

        if (parentIds.length) {
            this.loadChildStatuses(parentIds);
        }
    }

    updateLogo = (newLogo) => {
        const { companyResponse, mergeCompanyResponse } = this.props;
        mergeCompanyResponse({ _links: { logo: newLogo } });
        const updatedCompany = merge({}, companyResponse, {
            _links: { logo: newLogo },
        });
        HTTP.put(Response.getLink(companyResponse, 'edit'), updatedCompany)
            .then((r) => toast.success('Updated company logo'))
            .catch((e) => toast.error('Could not update Company'));
    };

    getAssessmentText = (assessment) => {
        let text = assessment._links['assessment-type'].name;
        if (assessment.expiryDate) {
            text += ` expires ${DateTime.fromISO(
                assessment.expiryDate
            ).toFormat('DD')}`;
        }

        return text;
    };

    loadChildStatuses = async (parentIds) => {
        const filter = Filters.convertFilterDataToRequestData({
            parentIds: parentIds.join(','),
        });

        const updates = await CampaignService.loadUpdatesList(filter);

        this.setState({
            ...this.state,
            childUpdates: groupBy(updates.data, 'parentId'),
        });
    };

    assessmentsAndClientRequirements = (companyResponse) => {
        return (
            <Card>
                <H2>Assessments</H2>
                <div className="completed-assessments">
                    {companyResponse.badges.map((assessment, key) => (
                        <div key={'company-card-assessment-' + key}>
                            {assessment._links.file ? (
                                <>
                                    <img
                                        className="logo"
                                        onDragStart={(e) => {
                                            e.preventDefault();
                                        }}
                                        alt={
                                            assessment._links['assessment-type']
                                                .name
                                        }
                                        title={this.getAssessmentText(
                                            assessment
                                        )}
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                            assessment._links.file.publicUrl
                                        }
                                    ></img>
                                </>
                            ) : null}
                        </div>
                    ))}
                    {!companyResponse.badges.length && (
                        <div>No Active Assessments</div>
                    )}
                </div>
                {companyResponse.currentSupplyChainInstance && (
                    <div className="mt-4">
                        <H2>Supply Chain Requirements</H2>
                        <ClientRequirements
                            supplyChainStatus="active"
                            company={companyResponse}
                            supplyChain={
                                companyResponse.currentSupplyChainInstance
                            }
                            selectedCategory={
                                companyResponse.supplyChainServiceProviderCategory
                            }
                        />
                    </div>
                )}
            </Card>
        );
    };

    getCampaignUpdatesEmptyCard = () => {
        let text = 'There are currently no company updates.';
        let showButton = true;

        return (
            <Card>
                <H2>Campaign Updates</H2>
                <p>{text}</p>
                {showButton && (
                    <Button
                        type="button"
                        intent="primary"
                        onClick={() =>
                            this.setState({ showCampaignUpdates: true })
                        }
                        className="float-right"
                    >
                        Update
                    </Button>
                )}
            </Card>
        );
    };

    campaignUpdates = (companyResponse) => {
        const campaignUpdates = companyResponse.inviteeStatuses ?? [];
        const { inviteeStatuses } = this.props.companyResponse;

        if (!isAdmin()) {
            return null;
        }

        if (!inviteeStatuses.length) {
            return this.getCampaignUpdatesEmptyCard();
        }

        return (
            <Card>
                <H2>Campaign Updates</H2>
                {campaignUpdates.map((status) => {
                    const childUpdates = this.state?.childUpdates[status.id];
                    let campaigns = [status.campaignName];

                    if (childUpdates?.length) {
                        childUpdates.forEach((child) => {
                            campaigns.push(child.campaignName);
                        });
                    }

                    const content = (
                        <div
                            className="campaign-update mb-3"
                            key={'campaign-update-' + status.id}
                        >
                            <strong>
                                {status.campaignName
                                    ? status.campaignName + ' - '
                                    : 'All Campaigns - '}
                            </strong>
                            <strong>{parseInviteeStatusText(status)}</strong>
                            <br />
                            {status.updateDetails}
                        </div>
                    );

                    return content;
                })}
                <Button
                    type="button"
                    intent="primary"
                    onClick={() => {
                        this.setState({ showCampaignUpdates: true });
                    }}
                    className="float-right"
                >
                    View All
                </Button>
            </Card>
        );
    };

    render() {
        const { companyResponse } = this.props;

        return (
            <div className="companyInfo">
                {isCompanyAdmin() ? (
                    <MissingInformation response={companyResponse} />
                ) : null}

                <div className="companyDetails">
                    <CompanyInfo
                        data={companyResponse}
                        onLogoUpdate={this.updateLogo}
                    />
                    <Card className="borderless">
                        {this.assessmentsAndClientRequirements(companyResponse)}
                        {this.campaignUpdates(companyResponse)}
                    </Card>

                    <Card>
                        <Address
                            title="Registered Company Address"
                            addressObject={companyResponse}
                        />
                    </Card>

                    <Card>
                        <H2>Primary Contact</H2>

                        <dl>
                            <dt>First Name</dt>
                            <dd>{companyResponse.primaryContactFirstName}</dd>
                            <dt>Surname</dt>
                            <dd>{companyResponse.primaryContactSurname}</dd>
                            <dt>Email</dt>
                            <dd>{companyResponse.primaryContactEmail}</dd>
                            <dt>Phone</dt>
                            <dd>
                                <Tel
                                    tel={
                                        companyResponse.primaryContactPhoneNumber ||
                                        ''
                                    }
                                />
                            </dd>
                        </dl>
                    </Card>
                    <Card className="large">
                        <RedFlagSection companyResponse={companyResponse} />
                    </Card>
                </div>
                <AllCompanyUpdates
                    company={companyResponse}
                    isOpen={this.state.showCampaignUpdates}
                    reloadData={this.props.reloadData}
                    onClose={() => {
                        this.setState({ showCampaignUpdates: false });
                    }}
                />
            </div>
        );
    }
}

export default Info;
