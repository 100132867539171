import { FormEvent, useEffect, useState } from 'react';
import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { useSelector } from 'react-redux';

import {
    IntegrationByAdminData,
    ElogbooksClientsFilter,
    ELBClientListData,
    ELBClientData,
    ElogbooksServiceProviderFilter,
    ELBServiceProviderListData,
    ELBServiceProviderData,
} from 'interface/Integrations';
import { CompanyData, CompanyFilter, CompanyListData } from 'interface/Company';
import { globalDebounce } from 'helpers/helpers';
import { Routing } from 'service';
import CompanyService from 'service/Company/CompanyService';
import {
    ButtonLink,
    Icon,
    MultiSelectRenderer,
    SuggestRenderer,
} from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import {
    handleSubmitAdminIntegations,
    loadELBClients,
    createElogbooksClientFilter,
    loadELBServiceProviders,
    createElogbooksSPFilter,
} from 'service/Integration/IntegrationService';

const IntegationsAdd = (props: any) => {
    const { history, match } = props;
    const [isLoading, setIsLoading] = useState(true);

    const [serviceProviderFilter, setServiceProviderFilter] = useState<
        Partial<CompanyFilter>
    >(CompanyService.createCompanyFilter());
    const [serviceProviders, setServiceProviders] = useState<CompanyData[]>();

    const [ELBClientFilter, setELBClientFilter] = useState<
        Partial<ElogbooksClientsFilter>
    >(createElogbooksClientFilter());
    const [ELBClients, setELBClients] = useState<ELBClientData[]>();

    const [ELBSPFilter, setELBSPFilter] = useState<
        Partial<ElogbooksServiceProviderFilter>
    >(createElogbooksSPFilter());
    const [ELBSPs, setELBSPs] = useState<ELBServiceProviderData[]>();

    const rootResponse = useSelector((state: any) => state.root.response);
    const userId = rootResponse.user.id;

    const [form, setForm] = useState<IntegrationByAdminData>({
        userId: userId,
        companyId: '',
        clientName: '',
        serviceProviderAdd: [],
        serviceProviderRemove: [],
    });

    const searchServiceProviders = (name?: string) => {
        const filter = {
            ...serviceProviderFilter,
            registeredCompanyName: name,
        };
        !name && delete filter.registeredCompanyName;
        setServiceProviderFilter(filter);
    };

    const searchClients = (client?: string) => {
        const filter = { ...ELBClientFilter, client: client };
        !client && delete filter.client;
        setELBClientFilter(filter);
    };

    const searchELBSps = (elbSpName?: string) => {
        const filter = { ...ELBSPFilter, name: elbSpName };
        !elbSpName && delete filter.name;
        //setELBSPFilter(filter);
        loadELBSPs(filter);
    };

    const loadServiceProviders = (callback?: Function) => {
        globalDebounce(
            async () => {
                const serviceProviderList: CompanyListData =
                    await CompanyService.loadList(serviceProviderFilter);
                setServiceProviders(serviceProviderList.companies);
                setIsLoading(false);
                callback && callback();
            },
            'IntegrationServiceProviders',
            80
        );
    };

    const loadClients = (callback?: Function) => {
        globalDebounce(
            async () => {
                const ELBClientList: ELBClientListData = await loadELBClients(
                    ELBClientFilter
                );
                setELBClients(ELBClientList.serviceProviders);
                callback && callback();
            },
            'ELBClients',
            80
        );
    };

    const loadELBSPs = (
        updatedFilter?: Partial<ElogbooksServiceProviderFilter>
    ) => {
        let filter = ELBSPFilter;

        if (updatedFilter) {
            filter = updatedFilter;
            setELBSPFilter(updatedFilter);
        }

        globalDebounce(
            async () => {
                const ELBSPList: ELBServiceProviderListData =
                    await loadELBServiceProviders(filter);
                setELBSPs(ELBSPList.serviceProviders);
            },
            'ELBSps',
            80
        );
    };

    const load = () => {
        (async () => {
            setIsLoading(true);
            loadServiceProviders();
            loadClients();
        })();
    };

    const selectSp = (item: any) => {
        if (item === null) {
            searchServiceProviders('');

            return;
        }

        setForm({
            ...form,
            companyId: item.id,
        });

        return;
    };

    const selectClient = (item: any) => {
        if (item === null) {
            searchClients('');
            setELBSPs([]);

            return;
        }

        setForm({
            ...form,
            clientName: item.elogbooksClient,
            serviceProviderAdd: [],
        });

        loadELBSPs({ ...ELBSPFilter, spClient: item.elogbooksClient });

        return;
    };

    const selectELBSP = (items: any) => {
        setForm({
            ...form,
            serviceProviderAdd: items,
        });
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(loadServiceProviders, [serviceProviderFilter]);
    useEffect(loadClients, [ELBClientFilter]);

    return (
        <div className="IntegrationsAddEdit">
            <H1>Add Elogbooks Integrations</H1>

            <Loading isLoading={isLoading}>
                <form
                    className="AddEdit"
                    onSubmit={(e: FormEvent<HTMLFormElement>) =>
                        handleSubmitAdminIntegations(e, form, history, match)
                    }
                >
                    <FormGroup
                        label="Prosure Service Provider"
                        key="serviceProvider"
                        inline={true}
                        className="form-fill required"
                        labelFor="serviceProvider"
                    >
                        <SuggestRenderer
                            id="serviceProvider"
                            onItemSelect={(item: any) => selectSp(item)}
                            resetOnClose={(e: any) => {
                                searchServiceProviders(e.target.value);
                            }}
                            placeholder={'Select Prosure Service Provider'}
                            valueProperty={'registeredCompanyName'}
                            items={serviceProviders}
                            onKeyUp={(e: any) => {
                                searchServiceProviders(e.target.value);
                            }}
                            isRequired={true}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Elogs Client"
                        key="elbClient"
                        inline={true}
                        className="form-fill required"
                        labelFor="elbClient"
                    >
                        <SuggestRenderer
                            id="elbClient"
                            onItemSelect={(item: any) => selectClient(item)}
                            placeholder={'Select Elogs Client'}
                            valueProperty={'elogbooksClient'}
                            items={ELBClients}
                            onKeyUp={(e: any) => {
                                searchClients(e.target.value);
                            }}
                            isRequired={true}
                        />
                    </FormGroup>

                    {typeof ELBSPs !== 'undefined' && (
                        <FormGroup
                            label={'Elogs Service Provider(s)'}
                            labelFor={'elbServiceProviders'}
                            className={
                                'bp3-inline form-fill multi-select-container'
                            }
                        >
                            <MultiSelectRenderer
                                id={'elbServiceProviders'}
                                onRemoveItem={(items: any) =>
                                    selectELBSP(items)
                                }
                                onItemSelect={(items: any) =>
                                    selectELBSP(items)
                                }
                                file={true}
                                items={ELBSPs}
                                selectedItems={form.serviceProviderAdd}
                                valueProperty={'elogbooksName'}
                                placeholder={'Select Service Provider(s)'}
                                onKeyUp={(e: any) => {
                                    searchELBSps(e.target.value);
                                }}
                            />
                        </FormGroup>
                    )}

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(
                                history,
                                match,
                                null
                            )}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </Loading>
        </div>
    );
};

export default IntegationsAdd;
