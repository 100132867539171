import { RouteComponentProps } from 'react-router';

import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { Option } from 'interfaces';
import React, { ComponentProps } from 'react';
import { ListData } from './index';

export interface ClientComponentProps extends RouteComponentProps<any> {
    clientResponse: ClientData;
    reloadData: Function;
}

export interface NotificationSelectProps {
    label: string;
    id: string;
    getRoles: Array<Option>;
    setRoles: Function;
}

export interface SupplyChainRequirementsCount {
    below: number;
    expiring: number;
    meetsAll: number;
}

export interface SupplyChainClientCount extends SupplyChainRequirementsCount {
    client?: ClientData;
    meetsAllPercentage: number;
    total: number;
    createdAt: string;
}

export interface SupplyChainTrendData extends ListData {
    clientCounts: SupplyChainClientCount[];
}

export interface SupplyChainStatusCountData {
    pending: number;
    active: number;
}

export interface ClientData {
    addressLine1: string;
    county: string;
    customerCode: string;
    id: string;
    isActive: boolean;
    name: string;
    elogsClientName: string;
    organisationType: string;
    postCode: string;
    town: string;
    _links: any;
    notificationInviteeDeclined: Array<string>;
    notificationWeeklySummary: Array<string>;
    notificationSPJoinedSupplyChain: Array<string>;
    notificationSPLeavingSupplyChain: Array<string>;
}

export interface ClientListData extends ListData {
    clients: ClientData[];
}

export interface ViewAsClientProps extends ComponentProps<any> {
    onClientSelect?: (clientData: ClientData, event: React.ChangeEvent) => void;
}

export const FILTER_SUBSCRIPTION_LEVELS = [
    NONE_SELECTED_OPTION,
    { label: 'Gold', value: 'gold' },
    { label: 'Silver', value: 'silver' },
    { label: 'Bronze', value: 'bronze' },
    { label: 'Legacy', value: 'legacy' },
];

export const FILTER_SUBSCRIPTION_STATUSES = [
    NONE_SELECTED_OPTION,
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Upcoming', value: 'PENDING' },
    { label: 'Expired', value: 'EXPIRED' },
    { label: 'No Data', value: 'no-data' },
];

export const CLIENT_SELECTOR_INCLUDES = [
    '/supply-chain/',
    '/reports/list'
];
