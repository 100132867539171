const routes = {
    client_details_supply_chain_config: {
        href: '/clients/list/:clientId/supply-chain-config',
        text: 'Supply Chain Configuration',
    },
    client_details_supply_chain_config_edit: {
        href: '/clients/list/:clientId/supply-chain-config/edit',
        text: 'Edit',
    },
};

export default routes;
