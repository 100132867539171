import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

import AssessmentTypeAccreditation from 'components/assessments/AssessmentTypeAccreditation';
import { ProtectedRoute } from 'components/elements';
import AssessmentTypeInstanceCommentsList from 'components/review/assessment-types/list/AssessmentTypeInstanceCommentsList';
import AssessmentTypeInstanceCompletedList from 'components/review/assessment-types/list/AssessmentTypeInstanceCompletedList';
import AssessmentTypeInstanceExpiringList from 'components/review/assessment-types/list/AssessmentTypeInstanceExpiringList';
import AssessmentTypeInstanceInReviewList from 'components/review/assessment-types/list/AssessmentTypeInstanceInReviewList';
import AssessmentTypeInstancePurchasedList from 'components/review/assessment-types/list/AssessmentTypeInstancePurchasedList';
import AssessmentTypeInstanceRefundedList from 'components/review/assessment-types/list/AssessmentTypeInstanceRefundedList';

import { delay } from 'helpers/helpers';
import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    ListCollection,
} from 'interface';
import { InstanceService } from 'service';

const AssessmentTypeInstanceList = (props: RouteComponentProps<any>) => {
    const { match } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [collection, setCollection] = useState<
        ListCollection<AssessmentTypeInstanceData>
    >({
        count: 0,
    } as ListCollection<AssessmentTypeInstanceData>);
    const defaultFilter: AssessmentTypeInstanceListFilter = {
        page: 1,
        limit: 8,
        status: 'review-assess',
        order: '-lastStatusUpdate',
    } as AssessmentTypeInstanceListFilter;

    const [filter, setFilter] =
        useState<AssessmentTypeInstanceListFilter>(defaultFilter);

    const load = async (searchFilter: AssessmentTypeInstanceListFilter) => {
        setIsLoading(true);

        const { collection, filter } = await InstanceService.loadList(
            props,
            searchFilter
        );

        setCollection(collection);
        setFilter(filter);
        await delay(0);
        setIsLoading(false);
    };

    return (
        <Switch>
            <ProtectedRoute
                path={`${match.url}/*/:id/view`}
                mode="review"
                component={AssessmentTypeAccreditation}
            />
            <ProtectedRoute
                path={`${match.url}/purchased-assessments`}
                component={AssessmentTypeInstancePurchasedList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <ProtectedRoute
                path={`${match.url}/assessments`}
                component={AssessmentTypeInstanceInReviewList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <ProtectedRoute
                path={`${match.url}/assessment-comments`}
                component={AssessmentTypeInstanceCommentsList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <ProtectedRoute
                path={`${match.url}/completed-assessments`}
                component={AssessmentTypeInstanceCompletedList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <ProtectedRoute
                path={`${match.url}/refunded-assessments`}
                component={AssessmentTypeInstanceRefundedList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <ProtectedRoute
                path={`${match.url}/expiring-assessments`}
                component={AssessmentTypeInstanceExpiringList}
                load={load}
                collection={collection}
                filter={filter}
                isLoading={isLoading}
                defaultFilter={defaultFilter}
                exact
            />
            <Redirect to={`${match.url}/assessments`} />
        </Switch>
    );
};

export default AssessmentTypeInstanceList;
