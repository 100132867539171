import { RouteComponentProps } from 'react-router';

import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { FlatObject } from 'interface';
import { ClientData } from 'interface/Client';
import { ListFilter, ListSearchFilter } from 'interface/List';

export enum CampaignStatus {
    INACTIVE = 0,
    ACTIVE = 1,
}

export const CampaignStatusMapping = {
    0: 'Inactive',
    1: 'Active',
};

const CAMPAIGN_OPTIONS_ACTIVE = { label: 'Active', value: 1 };
const CAMPAIGN_OPTIONS_INACTIVE = { label: 'Inactive', value: 0 };

export const CAMPAIGN_STATUS_OPTIONS = [
    CAMPAIGN_OPTIONS_ACTIVE,
    CAMPAIGN_OPTIONS_INACTIVE,
];

export const CAMPAINGN_UPDATE_TYPE_OPTIONS = [
    { label: 'Client Services Update', value: 'Client Services Update' },
    { label: 'Sales Phone Chaser', value: 'Sales Phone Chaser' },
    { label: 'Sales Email Chaser', value: 'Sales Email Chaser' },
    { label: 'Other', value: 'Other' },
];

export interface CampaignComponentProps extends RouteComponentProps<any> {
    campaign: Campaign;
}

export interface Campaign {
    client: ClientData;
    id: string;
    name: string;
    description: string;
    startDate: Date;
    status: CampaignStatus;
    numberOfInvitees: string;
    numberOfInviteesJoined: string;
    numberOfInviteesDeclined: string;
    ssipPreQualTotalExVat: number;
    ssipDtsTotalExVat: number;
    ssipVerifiedTotalExVat: number;
    ssipPreQualCount: number;
    ssipDtsCount: number;
    ssipVerifiedCount: number;
    numberOfJoinedSupplyChains: string;
    totalAssessmentRevenueExVat: string;
    totalSupplyChainRevenueExVat: string;
    emailTokens: FlatObject;
    _links: { client: any };
}

export interface CampaignListFilter extends ListFilter {
    client?: string;
    hasChases?: boolean;
}

export interface CampaignInvitee {
    id: string;
    campaign: Campaign;
    inviteeName: string;
    forename: string;
    surname: string;
    email: string;
    secondaryEmail: string;
    telephone: string;
    priority: CampaignPriority;
    referralCode: string;
    invitedDate: string;
    linkedAt: string;
    companyName: string;
    status: InviteeStatus;
    latestStatusUpdate?: string;
    latestStatusMessage?: string;
    latestUpdateType?: string;
    latestUpdatedByName?: string;
    statusIsDeclined?: boolean;
    joinedSupplyChainAt?: string;
    totalSpend?: number;
    _links: { [key: string]: any };
}

export interface CampaignRevenueByMonth {
    id: string;
    campaign: Campaign;
    monthStartingOn: string;
    supplyChainRevenueProsureExVat: string;
    supplyChainRevenueClientExVat: string;
    assessmentRevenueExVat: string;
}

export enum CampaignPriority {
    High = '3',
    Medium = '2',
    Low = '1',
    Unknown = '0',
}

export const CampaignPriorityMapping = {
    '3': '1. High',
    '2': '2. Medium',
    '1': '3. Low',
    '0': '4. Unknown',
};

export const CampaignPriorityOptions = [
    { label: '1. High', value: CampaignPriority.High },
    { label: '2. Medium', value: CampaignPriority.Medium },
    { label: '3. Low', value: CampaignPriority.Low },
    { label: '4. Unknown', value: CampaignPriority.Unknown },
];

export interface CampaignInviteeListFilter extends ListFilter {}
export interface CampaignUpdatesListFilter extends ListFilter {
    invitee?: string;
    purchasesOnly?: boolean;
}

export interface CampaignUpdatesSearchFilter {
    client: ListSearchFilter;
    campaignName?: ListSearchFilter;
    updateType?: ListSearchFilter;
    campaignSelect?: ListSearchFilter;
    updatedBy: ListSearchFilter;
}

export interface CampaignSearchListFilter {
    client: ListSearchFilter;
}

export interface InviteeStatus {
    id: string;
    updateDetails: string;
    updateType: string;
    updatedByName: string;
    updatedOn: string;
    campaignName: string;
    clientName: string;
    inviteeName: string;
    optionalFollowUpDate?: string;
}

export const InviteeStatusMapping = {
    '-1': 'Declined',
    '0': 'Pending',
    '1': 'Updated',
};

export const InviteeUpdateStatusOptions = [
    NONE_SELECTED_OPTION,
    { label: 'No Updates', value: 'no-updates' },
    { label: 'Has Updates', value: 'has-updates' },
    { label: 'Declined', value: 'declined' },
];

export const InviteeUpdateFollowUpDateOptions = [
    NONE_SELECTED_OPTION,
    { label: 'Follow Up Due', value: 'has-followup' },
];

export interface CampaignUpdate {
    id: string;
    clientName: string;
    campaignName: string;
    serviceProviderName: string;
    updateType: string;
    updatedOn: Date;
    updatedByName: string;
    updateDetails: string;
    optionalFollowUpDate: Date;
    purchaseName?: string;
    chasedAt?: Date;
    valueOfSale?: number;
    inviteeName?: string;
    _links: { client: any };
}
export interface CampaignEmailListFilter extends ListFilter {}
