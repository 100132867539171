import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, FormGroup, H1 } from '@blueprintjs/core';
import { HTTP, Response, Routing } from 'service';
import { ButtonLink, Icon, SuggestRenderer } from 'components/elements';
import { globalDebounce } from 'helpers/helpers';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
const mapDispatchToProps = {};

class UserAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collectionResponse: {},
            userSearchValue: '',
            form: {
                _links: {},
            },
        };

        this.load = this.load.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const { companyResponse, history, match } = this.props;
        const { form } = this.state;

        HTTP.post(Response.getLink(companyResponse, 'add-user'), form).then(
            (response) => {
                if (response) {
                    toast.success('User added.');
                    Routing.navigateUpOneLevel(history, match);

                    return true;
                }

                toast.error('Unable to add User to Company.');

                return false;
            }
        );
    }

    userSearch = (query = null) => {
        globalDebounce(
            () => {
                this.setState({ userSearchValue: query ?? '' });
                this.load(query);
            },
            'userSearch',
            250
        );
    };

    render() {
        const { collectionResponse, form } = this.state;
        const { history, match } = this.props;

        return (
            <div className="UserAdd">
                <H1>Add a User</H1>
                <form
                    className="AddEdit"
                    onSubmit={(e) => this.handleSubmit(e)}
                >
                    <FormGroup
                        label="User"
                        inline={true}
                        className="form-fill"
                        labelFor="user"
                    >
                        <SuggestRenderer
                            id="user"
                            onItemSelect={(item, event) => {
                                let links = Object.assign({}, form._links, {
                                    user: {
                                        href: Response.getLink(item, 'self'),
                                    },
                                });
                                let newFormState = Object.assign({}, form, {
                                    _links: links,
                                });

                                this.setState({ form: newFormState });

                                return;
                            }}
                            valueProperty="forename,surname"
                            items={collectionResponse.users || []}
                            isRequired={true}
                            onKeyUp={(e) => {
                                this.userSearch(e.target.value);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </div>
        );
    }

    load(query = '') {
        const { rootResponse } = this.props;

        let params = {
            companyId: '~',
            clientId: '~',
            modifiers: {
                companyId: { type: 'isNull' },
                clientId: { type: 'isNull' },
            },
            name: query,
        };

        HTTP.get(Response.getLink(rootResponse, 'users'), params).then(
            (response) => {
                if (response) {
                    this.setState({
                        collectionResponse: response.data,
                    });

                    return true;
                }

                toast.error('Unable to fetch Users');
                this.setState({ isLoading: false });

                return false;
            }
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserAdd)
);
