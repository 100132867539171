import {
    Button,
    FormGroup,
    H2,
    HTMLSelect,
    InputGroup,
} from '@blueprintjs/core';
import { SyntheticEvent, createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { updatingBreadcrumbResolves } from 'action';
import { Icon } from 'components/elements';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { delay } from 'helpers/helpers';
import { Option } from 'interface';
import {
    CampaignComponentProps,
    CampaignInvitee,
    CampaignPriority,
    CampaignPriorityOptions,
} from 'interface/Client/Campaign';
import { ClientService, Response } from 'service';
import CampaignService from 'service/Client/CampaignService';

interface CampaignAddEditComponentProps extends CampaignComponentProps {
    invitee?: CampaignInvitee;
    merge?: (form: CampaignInvitee) => void;
}

const CampaignInviteeAddEdit = (props: CampaignAddEditComponentProps) => {
    const { campaign, history, match, merge } = props;
    const [form, setForm] = useState<CampaignInvitee>({
        campaign,
        priority: CampaignPriority.High,
    } as CampaignInvitee);
    const [SPCategoryOptions, setSPCategoryOptions] = useState<Option[]>(
        () => []
    );
    const [isReset, setIsReset] = useState<boolean>(() => false);
    const [isEdit, setIsEdit] = useState<boolean>(() => false);
    const ref = createRef<HTMLFormElement>();
    const dispatch = useDispatch();

    const handleInviteeEdit = async () => {
        const response = await CampaignService.editInvitee(form);

        if (response) {
            toast.success('Successfully Updated Campaign');
            typeof merge === 'function' && merge(form);

            history.goBack();
        }

        return;
    }

    const handleInviteeAdd = async () => {
        const response = await CampaignService.addInvitee(campaign, form);

        if (!response) {
            return;
        }

        toast.success('Successfully Created New Invitee');

        typeof merge === 'function' && merge(form);

        await delay(1); // wait for isReset to trigger (for create & add another)
        if (isReset) {
            (ref.current as HTMLFormElement).reset();
            setForm({
                campaign: campaign,
                priority: CampaignPriority.High,
            } as CampaignInvitee);
            setIsReset(false);
        } else {
            history.goBack();
        }
    }

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (isEdit) {
            handleInviteeEdit();
        }
        else {
            handleInviteeAdd();
        }
    };

    useEffect(() => {
        // wait for campaign.client then fetch categories
        campaign &&
            campaign.client &&
            (async () => {
                const categories = await ClientService.getSPCategories(
                    campaign.client
                );
                const options = categories.map((category) => {
                    return {
                        value: Response.getLink(category, 'self'),
                        label: category.name,
                    };
                });
                setSPCategoryOptions([NONE_SELECTED_OPTION, ...options]);
            })();
    }, [campaign]);

    useEffect(() => {
        const campaignId = match.params.campaignId;
        const inviteeId = match.params.inviteeId;
        if (!campaignId || !inviteeId) {
            return;
        }
        setIsEdit(true);
        loadInvitee(campaignId, inviteeId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.campaignId, match.params.inviteeId]);

    const loadInvitee = async (campaignId: string, inviteeId: string) => {

        const response = await CampaignService.loadByInviteeId(campaignId, inviteeId);
        setForm({ ...form, ...response,  });
        dispatch(
            updatingBreadcrumbResolves({
                invitee: response,
            })
        );
    }

    return (
        <div className="CampaignAddEdit">
            <H2>{`${isEdit ? 'Edit' : 'Add'} Campaign Invitee`}</H2>
            <form className="AddEdit" onSubmit={handleSubmit} ref={ref}>
                <FormGroup
                    label="Invitee Name"
                    inline={true}
                    className="form-fill required"
                    labelFor="inviteeName"
                >
                    <InputGroup
                        id="inviteeName"
                        placeholder="Invitee Name"
                        minLength={2}
                        maxLength={100}
                        value={form.inviteeName}
                        onChange={(e: any) =>
                            setForm({
                                ...form,
                                inviteeName: e.target.value,
                            })
                        }
                        required
                    />
                </FormGroup>

                <FormGroup
                    label="Forename"
                    inline={true}
                    className="form-fill required"
                    labelFor="forename"
                >
                    <InputGroup
                        id="forename"
                        placeholder="Forename"
                        minLength={2}
                        maxLength={75}
                        value={form.forename}
                        onChange={(e: any) =>
                            setForm({ ...form, forename: e.target.value })
                        }
                        required
                    />
                </FormGroup>

                <FormGroup
                    label="Surname"
                    inline={true}
                    className="form-fill"
                    labelFor="surname"
                >
                    <InputGroup
                        id="surname"
                        placeholder="Surname"
                        minLength={2}
                        maxLength={75}
                        value={form.surname}
                        onChange={(e: any) =>
                            setForm({ ...form, surname: e.target.value })
                        }
                    />
                </FormGroup>

                <FormGroup
                    label="Email Address"
                    labelFor="email"
                    inline={true}
                    className="form-fill required"
                >
                    <InputGroup
                        id="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        value={form.email}
                        onChange={(e: any) =>
                            setForm({ ...form, email: e.target.value })
                        }
                        required
                    />
                </FormGroup>
                <FormGroup
                    inline
                    label="Secondary Email Address"
                    labelFor="secondary-email"
                    className="form-fill"
                >
                    <InputGroup
                        id="secondary-email"
                        type="email"
                        autoComplete="email"
                        placeholder="Secondary Email Address"
                        value={form.secondaryEmail}
                        onChange={(e: any) =>
                            setForm({ ...form, secondaryEmail: e.target.value })
                        }
                    />
                </FormGroup>
                <FormGroup
                    label="Telephone"
                    labelFor="phone"
                    inline={true}
                    className="form-fill"
                >
                    <InputGroup
                        id="phone"
                        fill={true}
                        type="tel"
                        placeholder="Telephone"
                        pattern="[0-9\+\-\s]+"
                        title="only +,- or numbers are accepted"
                        value={form.telephone}
                        onChange={(e: any) =>
                            setForm({ ...form, telephone: e.target.value })
                        }
                    />
                </FormGroup>

                <FormGroup
                    label="Priority for Client"
                    key="priority"
                    inline={true}
                    className="form-fill required"
                    labelFor="priority"
                >
                    <HTMLSelect
                        id="priority"
                        fill={true}
                        value={form.priority}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                priority: e.target.value as CampaignPriority,
                            })
                        }
                        options={CampaignPriorityOptions}
                    />
                </FormGroup>

                <FormGroup
                    label="Service Provider Category"
                    key="sp-category"
                    inline={true}
                    className="form-fill required"
                    labelFor="sp-category"
                >
                    <HTMLSelect
                        id="sp-category"
                        fill={true}
                        value={form._links && form._links["sp-category"]?.href}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                _links: {
                                    ...form._links,
                                    'sp-category': {
                                        method: 'GET',
                                        href: e.target.value,
                                    },
                                },
                            })
                        }
                        required
                        options={SPCategoryOptions}
                    />
                </FormGroup>

                <FormGroup>
                    <Button
                        type="button"
                        className="float-left"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <Icon icon="ban" />
                        Cancel
                    </Button>

                    {
                        !isEdit && <>
                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right me-2"
                            >
                                <Icon icon="paper-plane" />
                                Create & Finish
                            </Button>
                            <Button
                                type="submit"
                                intent="primary"
                                onClick={() => setIsReset(true)}
                            >
                                <Icon icon="paper-plane" />
                                Create & Add another
                            </Button>
                        </>
                    }

                    {
                        isEdit && <>
                            <Button
                                type="submit"
                                intent="primary"
                                className="float-right me-2"
                            >
                                <Icon icon="paper-plane" />
                                Save Changes
                            </Button>
                        </>
                    }
                </FormGroup>
            </form>
        </div>
    );
};

export default withRouter(CampaignInviteeAddEdit);
