import {
    Breadcrumb,
    Breadcrumbs,
    Button,
    Classes,
    Dialog,
    Intent,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import Storage from '@elb/storage';
import { get } from 'lodash';
import { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { logout } from 'action/auth';
import { getRootResponse } from 'action/root';
import { setTasksCount } from 'action/tasks';
import Admin from 'components/admin/Admin';
import AssessmentTypeRoot from 'components/assessment-types/AssessmentTypeRoot';
import Benefits from 'components/benefits/Benefits';
import ClientBio from 'components/clients/ClientBio';
import Clients from 'components/clients/Clients';
import Company from 'components/company/Company';
import Tasks from 'components/company/Tasks';
import ContactUs from 'components/contact-us/ContactUs';
import Dashboard from 'components/dashboard/Dashboard';
import Icon from 'components/elements/Icon';
import LoadingIndicator from 'components/elements/LoadingIndicator';
import ViewAsClient from 'components/elements/admin/view-as-client/ViewAsClient';
import Reports from 'components/elements/client/report';
import Review from 'components/review/Review';
import ServiceProviders from 'components/service-provider/ServiceProviders';
import Shop from 'components/shop/Shop';
import SupplyChain from 'components/supply-chain/SupplyChain';
import SupplyChains from 'components/supply-chains/SupplyChains';
import Integrations from 'components/user/Integrations/Integrations';
import Profile from 'components/user/Profile';
import {
    globalDebounce,
    isAssessorReviewer,
    isCompanyAdmin,
} from 'helpers/helpers';
import { CLIENT_SELECTOR_INCLUDES } from 'interface';
import routes from 'routes';
import { HTTP, Response } from 'service';

const mapStateToProps = (state) => {
    return {
        lastUpdatedAt: state.breadcrumb.lastUpdatedAt,
        resolves: state.breadcrumb.resolves,
        rootResponse: state.root.response,
        token: state.auth.token,
    };
};
const mapDispatchToProps = {
    logout: logout,
    getRootResponse: getRootResponse,
    setTasksCount: setTasksCount,
};

class Main extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [],
        };

        this.props.history.listen((location, action) => {
            this.setBreadcrumbState(location);
            this.refreshTaskCount();
        });

        this.setBreadcrumbState = this.setBreadcrumbState.bind(this);
    }

    componentDidMount() {
        this.refreshTaskCount();
        //GTAG record page load performance
        typeof window.measureLoadPerfomance === 'function' &&
            window.loadAnalytics();

        globalDebounce(
            () => this.setBreadcrumbState(this.props.location),
            'breadcrumbs-update',
            1
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastUpdatedAt !== this.props.lastUpdatedAt) {
            this.setBreadcrumbState(this.props.location);
        }
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    shouldShowClientSelector() {
        const { location } = this.props;

        for (let i = 0; i < CLIENT_SELECTOR_INCLUDES.length; i++) {
            const pathInclude = CLIENT_SELECTOR_INCLUDES[i];

            if (location.pathname.includes(pathInclude)) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { location } = this.props;
        const showClientSelector = this.shouldShowClientSelector();

        return (
            <div className="Main" onClick={this.props.onClick}>
                {!this.props.rootResponse.user.acceptedTermsOfUse ||
                !this.props.rootResponse.user.acceptedPrivacy ? (
                    <>
                        <Button
                            className="add-to-supply-chain icon-only"
                            onClick={() => {
                                return false;
                            }}
                            title="Accept or Decline Invitation"
                        >
                            <Icon icon="heart" className="grey" />
                        </Button>

                        <Dialog
                            title="Terms of Use and Privacy"
                            autoFocus={true}
                            canEscapeKeyClose={false}
                            canOutsideClickClose={false}
                            enforceClose={true}
                            usePortal={true}
                            isCloseButtonShown={false}
                            isOpen={true}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                I confirm that I have read and accepted the
                                Prosure360{' '}
                                <a
                                    href="https://prosure360.co.uk/terms_of_use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>Terms of use</span>
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://prosure360.co.uk/privacy_policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>Privacy Policy</span>
                                </a>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className="dialog-footer-buttons">
                                    <div
                                        className={
                                            Classes.DIALOG_FOOTER_ACTIONS
                                        }
                                    >
                                        <Button
                                            intent={Intent.WARNING}
                                            onClick={() => {
                                                this.props.logout();
                                            }}
                                        >
                                            Decline
                                        </Button>
                                        <Button
                                            intent={Intent.SUCCESS}
                                            onClick={() => {
                                                this.acceptTerms();
                                            }}
                                        >
                                            Accept
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </>
                ) : (
                    <>
                        <nav className="breadcrumbs-wrap">
                            <Breadcrumbs
                                items={this.state.breadcrumbs}
                                breadcrumbRenderer={(props) => {
                                    return isMobile || props.href === null ? (
                                        <Breadcrumb {...props} />
                                    ) : (
                                        <Tooltip2 content={props.text}>
                                            <Breadcrumb {...props} />
                                        </Tooltip2>
                                    );
                                }}
                            />
                        </nav>

                        {showClientSelector && isAssessorReviewer() && (
                            <ViewAsClient
                                onlyLoadCafmClients={
                                    location.pathname.includes('elogs-cafm-stats') 
                                    || undefined
                                }
                            />
                        )}

                        <Switch>
                            <Route path="/dashboard" component={Dashboard} />
                            <Route path="/client-bio" component={ClientBio} />
                            <Route path="/tasks" component={Tasks} />
                            <Route path="/shop" component={Shop} />
                            <Route
                                path="/service-providers"
                                component={ServiceProviders}
                            />
                            <Route
                                path="/supply-chains"
                                component={SupplyChains}
                            />
                            <Route
                                path="/supply-chain"
                                component={SupplyChain}
                            />
                            <Route path="/company" component={Company} />
                            <Route
                                path="/assessments"
                                component={AssessmentTypeRoot}
                            />
                            <Route path="/clients" component={Clients} />
                            <Route path="/review" component={Review} />
                            <Route path="/admin" component={Admin} />
                            <Route path="/contact-us" component={ContactUs} />
                            <Route path="/benefits" component={Benefits} />
                            <Route path="/profile" component={Profile} />
                            <Route
                                path="/integrations"
                                component={Integrations}
                            />
                            <Route path="/reports" component={Reports} />

                            <Redirect from="/" to="/dashboard" />

                            <Route component={() => 'Error'} />
                        </Switch>
                    </>
                )}
            </div>
        );
    }

    acceptTerms() {
        HTTP.patch(
            Response.getLink(this.props.rootResponse.user, 'accept-terms'),
            {}
        ).then((response) => {
            if (response) {
                toast.success('Terms accepted');

                this.props.getRootResponse();

                return true;
            }

            toast.error('Unable to accept terms');

            return false;
        });
    }

    getBreadcrumbTextMatchedResolve(text) {
        if (Array.isArray(text)) {
            let atLeastOneMatches = false;

            text.forEach((item) => {
                atLeastOneMatches = atLeastOneMatches || item.match(/\{(.*)\}/);
            });

            return atLeastOneMatches;
        } else if (text) {
            let matches = text.match(/\{(.*)\}/);

            return matches !== null ? matches[1] : null;
        }

        return null;
    }

    getBreadcrumbResolveFromState(text) {
        if (Array.isArray(text)) {
            let textParts = text.map((item) => {
                return this.getBreadcrumbResolveFromState(item);
            });

            return textParts.join(' ');
        }

        if (this.getBreadcrumbTextMatchedResolve(text)) {
            const value = get(
                this.props.resolves,
                this.getBreadcrumbTextMatchedResolve(text)
            );
            if (value === null) {
                return <LoadingIndicator inline={true} size="xs" />;
            }

            return get(
                this.props.resolves,
                this.getBreadcrumbTextMatchedResolve(text),
                <LoadingIndicator inline={true} size="xs" />
            );
        }

        return text;
    }

    setBreadcrumbState(location) {
        const matches = [];

        location.pathname
            .replace(/\/$/, '')
            .split('/')
            .reduce((previous, current) => {
                const pathSection = `${previous}/${current}`;

                let breadcrumbMatch;

                Object.values(routes).some(
                    ({
                        breadcrumb,
                        href,
                        icon,
                        text,
                        skipNavigation,
                        skipBreadcrumb,
                    }) => {
                        let baseHref = href.split('?')[0];
                        let params = href.split('?')[1];

                        const match = matchPath(pathSection, {
                            exact: true,
                            path: baseHref,
                        });

                        if (this.getBreadcrumbTextMatchedResolve(text)) {
                            text = this.getBreadcrumbResolveFromState(text);
                        }

                        if (match && !skipBreadcrumb) {
                            breadcrumbMatch = {
                                breadcrumb: '',
                                path: href,
                                href: skipNavigation
                                    ? null
                                    : '#' +
                                      match.url +
                                      (params ? '?' + params : ''),
                                icon: null,
                                text,
                                match,
                            };

                            return true;
                        }

                        return false;
                    }
                );

                if (breadcrumbMatch) {
                    matches.push(breadcrumbMatch);
                }

                return pathSection;
            });

        if (!this.isCancelled) {
            this.setState({ breadcrumbs: matches });
        }

        return matches;
    }

    refreshTaskCount = async () => {
        globalDebounce(
            async () => {
                const user = this.props.rootResponse?.user;
                const token = Storage.load('auth.token');
                if (!user || !token || !isCompanyAdmin()) {
                    return;
                }
                const res = await HTTP.action('get', '/tasks/count');
                res && this.props.setTasksCount(res.data);
            },
            'refresh-task-count',
            1000
        );
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
