import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    TextArea,
} from '@blueprintjs/core';

import { Response, HTTP } from 'service';
import { SupplyChain } from 'interface/SupplyChain';
import './index.scss';

interface DeclineSupplyChainInvitationDialogProps {
    history: RouterHistory;
    supplyChain: SupplyChain;
}

const DeclineSupplyChainInvitationDialog = (
    props: DeclineSupplyChainInvitationDialogProps
) => {
    const { supplyChain, history } = props;
    const clientName = supplyChain._links?.client?.name ?? 'Client';
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [declineReason, setDeclineReason] = useState<string>('');

    const closeDialog = () => {
        setDeclineReason('');
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.stopPropagation();
        event.preventDefault();

        const declineLink = Response.getLink(supplyChain, 'decline-invite');
        if (declineLink) {
            await HTTP.post(Response.getLink(supplyChain, 'decline-invite'), {
                reason: declineReason,
            });

            toast.success(`Declined invite to  ${clientName}'s supply chain`);
            history.push('/supply-chains/pending');
        } else {
            closeDialog();
        }
    };

    return (
        <>
            <Button
                className="float-right decline-button"
                onClick={openDialog}
                intent="warning"
                title="Decline Supply Chain"
            >
                Decline
            </Button>
            <Dialog
                title={`Decline Invitation to ${clientName} Supply Chain.`}
                className="decline-reason"
                onClose={closeDialog}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isDialogOpen}
            >
                <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) =>
                        submit(event)
                    }
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div className="mb-2">
                            By declining you will no longer receive
                            communications from this Supply Chain.
                            {' ' + clientName} will be notified of your decline
                            and this could result in putting your relationship
                            with the {clientName} at risk.
                        </div>
                        <FormGroup
                            label="Decline Reason"
                            inline={true}
                            className="form-fill required"
                            labelFor="reason"
                        >
                            <TextArea
                                id="reason"
                                minLength={1}
                                maxLength={1000}
                                value={declineReason}
                                onChange={(
                                    e: FormEvent<HTMLTextAreaElement>
                                ) => {
                                    const { value } =
                                        e.target as HTMLTextAreaElement;
                                    setDeclineReason(value);
                                }}
                                required
                            />
                        </FormGroup>
                        <FormGroup className={Classes.DIALOG_FOOTER}>
                            <Button
                                type="button"
                                className="float-left"
                                onClick={closeDialog}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                intent="success"
                                className="float-right"
                            >
                                Continue
                            </Button>
                        </FormGroup>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default DeclineSupplyChainInvitationDialog;
