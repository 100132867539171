import { H1 } from '@blueprintjs/core';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Table } from 'components/elements';
import type { FlatObject } from 'interface';

interface ReportInterface {
    name: string;
    description: string;
    link: string;
}

const ReportList = (props: RouteComponentProps<any>) => {
    const columns: FlatObject[] = [
        { name: 'Report Name', property: 'name', sortable: true, grow: 2 },
        { name: 'Report Description', property: 'description', grow: 4 },
        {
            name: '',
            type: 'callback',
            grow: 1,
            callback: (row: FlatObject) => {
                return (
                    <div className="flex-end full-width">
                        <Link
                            className="bp3-button bp3-intent-primary"
                            to={row.link}
                        >
                            View
                        </Link>
                    </div>
                );
            },
        },
    ];

    const data = [
        {
            name: 'Active Supply Chain',
            description:
                'View the details of all Service Providers currently in my Supply Chain',
            link: props.match.url + '/active-supply-chain-report',
        },
    ] as ReportInterface[];
    const [sortedData, setSortedData] = useState<ReportInterface[]>(data);
    const [filters, setFilters] = useState('name');
    const onFilter = (filters: FlatObject) => {
        setFilters(filters.order);
        let order = 1;
        if (filters.order.substr(0, 1) === '-') {
            order = -1;
        }
        const sorted = data.sort((a, b) => {
            if (a.name > b.name) return 1 * order;
            if (a.name < b.name) return -1 * order;

            return 0;
        });
        setSortedData(sorted);
    };

    return (
        <div className="ReportList page">
            <H1>Reports</H1>
            <Table
                data={sortedData}
                columns={columns}
                onSort={onFilter}
                ordering={filters}
            />
        </div>
    );
};

export default ReportList;
