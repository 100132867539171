import { Component } from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Tabs, Tab, FormGroup } from '@blueprintjs/core';
import { updatingBreadcrumbResolves } from 'action/breadcrumb';
import { Icon, ProtectedRoute, HTMLSelect } from 'components/elements';
import { Loading } from 'components/elements/wrappers';
import { HTTP, Routing, Response } from 'service';
import AssessmentTypeSummary from 'components/admin/assessment-types/AssessmentTypeSummary';
import AssessmentTypeAddEdit from 'components/admin/assessment-types/AssessmentTypeAddEdit';
import AssessmentTypeQuestionList from 'components/admin/assessment-types/AssessmentTypeQuestionList';
import OrganisationSizesPricingForm from 'components/pricing-matrices/OrganisationSizesPricingForm';
import AssessmentTypeHistory from 'components/admin/assessment-types/AssessmentTypeHistory';
import AssessmentTypeSLAs from 'components/admin/assessment-types/AssessmentTypeSLAs';
import {
    ASSESSMENT_TYPE_VERSION_KEYS,
    ASSESSMENT_TYPE_VERSION_OPTIONS,
} from 'constants/AssessmentTypeVersion';

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    updatingBreadcrumbResolves: updatingBreadcrumbResolves,
};

class AssessmentTypeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assessmentTypeId: props.match.params.assessmentTypeId,
            isLoading: false,
            response: {},
            versions: [],
        };

        this.load = this.load.bind(this);
        this.mergeResponse = this.mergeResponse.bind(this);
    }

    componentDidUpdate(prevProps) {
        const currentAssessmentTypeId =
            this.props.match.params.assessmentTypeId;
        const newAssessmentTypeId = prevProps.match.params.assessmentTypeId;

        if (newAssessmentTypeId !== currentAssessmentTypeId) {
            this.setState((prevState) => ({
                ...prevState,
                assessmentTypeId: currentAssessmentTypeId,
            }));
        }
    }

    componentDidMount() {
        this.props.updatingBreadcrumbResolves({ accreditationType: undefined });
        this.load();
    }

    mergeResponse(data = {}) {
        const { response } = this.state;

        data.isActive = data.isActive === 'true' || data.isActive === true;
        data.isSSIPAccreditation =
            data.isSSIPAccreditation === 'true' ||
            data.isSSIPAccreditation === true;
        data.isSSIPDTS = data.isSSIPDTS === 'true' || data.isSSIPDTS === true;
        data.isVisibleToClient =
            data.isVisibleToClient === 'true' ||
            data.isVisibleToClient === true;
        data.isSLAApplicable =
            data.isSLAApplicable === 'true' || data.isSLAApplicable === true;

        this.setState({ response: { ...response, ...data } }, () =>
            this.load(data)
        );
    }

    render() {
        const { isLoading, response, versions } = this.state;
        const { location, match, history } = this.props;

        const tabId = Routing.getSelectedTabId(location.pathname, match.url);
        const pricingBands = [
            {
                name: 'Standard assessment fee excluding tax',
                property: 'standardAssessmentFeeExcludingTaxInput',
                propertyField: 'standardAssessmentFeeExcludingTax',
            },
            {
                name: 'Standard assessment fee tax',
                property: 'standardAssessmentFeeTaxInput',
                propertyField: 'standardAssessmentFeeTax',
            },
            {
                name: 'Standard assessment fee including tax',
                property: 'standardAssessmentFeeIncludingTaxInput',
                propertyField: 'standardAssessmentFeeIncludingTax',
            },
            {
                name: 'Fast track excluding tax',
                property: 'fastTrackExcludingTaxInput',
                propertyField: 'fastTrackExcludingTax',
            },
            {
                name: 'Fast track tax',
                property: 'fastTrackTaxInput',
                propertyField: 'fastTrackTax',
            },
            {
                name: 'Fast track including tax',
                property: 'fastTrackIncludingTaxInput',
                propertyField: 'fastTrackIncludingTax',
            },
        ];

        return (
            <div className="AssessmentTypeDetails">
                <Loading
                    isLoading={isLoading || typeof response.id === 'undefined'}
                >
                    <div className="assessment-type-header-container">
                        <div className="assessment-type-title">
                            <h1 className="clearfix">{response.name}</h1>
                        </div>
                        <div className="assessment-type-version">
                            <FormGroup
                                label="Version"
                                inline={true}
                                labelFor="version"
                            >
                                <HTMLSelect
                                    value={response.version}
                                    onChange={(event) => {
                                        const response = versions.find(
                                            (r) =>
                                                r.version === event.target.value
                                        );

                                        history.push(
                                            '/admin' +
                                                Response.getLink(
                                                    response,
                                                    'self'
                                                )
                                        );
                                        this.setState({
                                            ...this.state,
                                            response: response,
                                        });
                                    }}
                                    options={versions.map((option) => {
                                        return {
                                            label:
                                                ASSESSMENT_TYPE_VERSION_OPTIONS[
                                                    option.versionStatus
                                                ] +
                                                ' ' +
                                                option.version,
                                            value: option.version,
                                        };
                                    })}
                                    rightElement={this.renderVersionRightElement()}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                        <Tab id={'/summary'}>
                            <Link to={`${match.url}/summary`}>
                                <Icon icon="info" />
                                Summary
                            </Link>
                        </Tab>
                        <Tab id={'/questions'}>
                            <Link to={`${match.url}/questions`}>
                                <Icon icon="question-circle" />
                                Questions
                            </Link>
                        </Tab>

                        {response.type === 'prosure' && (
                            <Tab id={'/pricing'}>
                                <Link to={`${match.url}/pricing`}>
                                    <Icon icon="pound-sign" />
                                    Commercial
                                </Link>
                            </Tab>
                        )}

                        {Response.getLink(response, 'view-slas') && (
                            <Tab id={'/slas'}>
                                <Link to={`${match.url}/slas`}>
                                    <Icon icon="handshake" />
                                    SLAs
                                </Link>
                            </Tab>
                        )}

                        <Tab id={'/history'}>
                            <Link to={`${match.url}/history`}>
                                <Icon icon="clock" />
                                History
                            </Link>
                        </Tab>
                    </Tabs>
                    <div className="TabContent">
                        <Switch>
                            <ProtectedRoute
                                path={`${match.url}/summary`}
                                component={AssessmentTypeSummary}
                                assessmentTypeResponse={response}
                                mergeResponse={this.mergeResponse}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/summary/edit`}
                                component={AssessmentTypeAddEdit}
                                assessmentTypeResponse={response}
                                mergeResponse={this.mergeResponse}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/questions`}
                                component={AssessmentTypeQuestionList}
                                assessmentTypeResponse={response}
                                exact
                            />
                            {response.type === 'prosure' && (
                                <ProtectedRoute
                                    path={`${match.url}/pricing`}
                                    component={OrganisationSizesPricingForm}
                                    loadLink={Response.getLink(
                                        response,
                                        'pricings'
                                    )}
                                    updateLink={Response.getLink(
                                        response,
                                        'updatePricings'
                                    )}
                                    pricingBands={pricingBands}
                                    exact
                                />
                            )}
                            <ProtectedRoute
                                path={`${match.url}/slas`}
                                component={AssessmentTypeSLAs}
                                assessmentType={response}
                                mergeResponse={this.mergeResponse}
                                exact
                            />
                            <ProtectedRoute
                                path={`${match.url}/history`}
                                component={AssessmentTypeHistory}
                                assessmentTypeResponse={response}
                                exact
                            />
                            <Redirect to={`${match.url}/summary`} />
                        </Switch>
                    </div>
                </Loading>
            </div>
        );
    }

    loadVersions(response) {
        const versionURL = Response.getLink(
            response,
            'assessment-type-versions'
        );

        if (versionURL) {
            HTTP.get(versionURL).then((response) => {
                const state = {
                    ...this.state,
                    versions: response.data.assessmentTypes,
                };
                this.setState(state);
                return true;
            });
        }
    }

    load(data = {}) {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        HTTP.get(`/assessment-types/${this.state.assessmentTypeId}`, {
            datagroup: 'details',
        }).then((response) => {
            if (response) {
                const newData = {
                    ...response.data,
                    ...data,
                    _links: response.data._links,
                };

                this.loadVersions(newData);

                this.setState({
                    isLoading: false,
                    response: newData,
                });

                this.props.updatingBreadcrumbResolves({
                    assessmentType: { name: response.data.name },
                });
                return true;
            }

            toast.error('Unable to fetch Assessment Type details');
            this.setState({ isLoading: false });

            return false;
        });
    }

    renderVersionRightElement = () => {
        const { response } = this.state;
        return (
            <div
                className={
                    'assessment-type-version-status ' +
                    ASSESSMENT_TYPE_VERSION_KEYS[response.versionStatus]
                }
            >
                {ASSESSMENT_TYPE_VERSION_OPTIONS[response.versionStatus]}
            </div>
        );
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentTypeDetails);
