import { FormGroup } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    Accordion,
    CompanyCard,
    HTMLSelect,
    LoadingIndicator,
    MultiSelectRenderer,
    Paginator,
    ResultsCount,
    SearchWidget,
} from 'components/elements';
import MultiLevelSwitchArray from 'components/elements/multiLevelSwitch/MultiLevelSwitchArray';
import LinkedFilter from 'components/linked-filter/linked-filter';
import { assessmentCategoryAndTypeOptions } from 'constants/assessmentTypeTypes';
import {
    flattenChildren,
    isClientViewer,
    parseOptionGroups,
} from 'helpers/helpers';
import { Filters, HTTP, Location, Response } from 'service';
import InsurancesFilter from './InsurancesFilter';
import RatingFilter from './RatingFilter';
import RedFlagFilter from './RedFlagFilter';

const Search = (props) => {
    const rootResponse = useSelector((state) => state.root.response);
    const [clientResponse, setClientResponse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilters, setIsLoadingFilters] = useState(true);
    const [searchResponse, setSearchResponse] = useState({});
    const [serviceProviders, setServiceProviders] = useState();
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [services, setServices] = useState([]);
    const [isInit, setIsInit] = useState(true);
    const [clear, setClear] = useState(false);
    const defaultFilters = {
        regions: [],
        categories: [],
        services: [],
        limit: 8,
        order: '-rating',
        isProsurePreQualified: null,
        isSSIPAccreditation: null,
    };
    const [searchFilters, setSearchFilters] = useState(defaultFilters);
    const [categoryFilters, setCategoryFilters] = useState({
        isClient: false,
        categories: [{ name: 'None Selected' }],
        selected: undefined,
    });
    const searchOrderingDefaultValue = 'Company Rating: Highest to Lowest';
    const ssipFilterParentNames = assessmentCategoryAndTypeOptions.map(
        (category) => category.id
    );

    const sortFilters = [
        {
            key: 'registeredCompanyName',
            value: 'Company Name: A-Z',
        },
        {
            key: '-registeredCompanyName',
            value: 'Company Name: Z-A',
        },
        {
            key: '-createdAt',
            value: 'Joined Prosure Date: Newest First',
        },
        {
            key: 'createdAt',
            value: 'Joined Prosure Date: Oldest First',
        },
        {
            key: '-rating',
            value: 'Company Rating: Highest to Lowest',
        },
        {
            key: 'rating',
            value: 'Company Rating: Lowest to Highest',
        },
    ];

    useEffect(() => {
        if (isLoadingFilters) {
            setIsLoadingFilters(false);
            loadClient();
            loadServices();
            loadRegions();
            loadCategoryFilters();
        }

        const urlFilters = Filters.getFilterStateFromUrl(
            props.location.search.slice(1),
            {}
        );

        setSearchFilters({
            ...defaultFilters,
            ...urlFilters
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadClient = async () => {
        const clientLink = Response.getLink(rootResponse, 'client');
        if (clientLink !== null) {
            const response = await HTTP.get(clientLink, {
                datagroup: 'details',
            });
            setClientResponse(response.data);
        }
    };

    const loadServices = async () => {
        const response = await HTTP.get('/registration/services', {
            limit: 999,
            order: 'name',
        });

        if (response) {
            const services = parseOptionGroups(response.data.services);

            setServices(services);
            setSelectedServices(loadSelected(services, 'services'));
        } else {
            toast.error('Unable to load Services');
        }
    };

    const loadRegions = async () => {
        const response = await HTTP.get('/registration/regions', {
            limit: 999,
            order: 'name',
        });

        if (response) {
            const regions = parseOptionGroups(response.data.regions);

            setRegions(regions);
            setSelectedRegions(loadSelected(regions, 'regions'));
        } else {
            toast.error('Unable to load Regions');
        }
    };

    const loadCategoryFilters = async () => {
        const clientServiceProviderCategoriesUrl = Response.getLink(
            rootResponse,
            'client-service-provider-categories'
        );

        if (clientServiceProviderCategoriesUrl) {
            await HTTP.get(clientServiceProviderCategoriesUrl)
                .then((response) => {
                    const urlFilters = Filters.getFilterStateFromUrl(
                        props.location.search.slice(1),
                        {}
                    );
                    let selected = undefined;
                    if (urlFilters.category) {
                        selected = response.data.categories.find(
                            (category) => category.id === urlFilters.category
                        );
                    }

                    setCategoryFilters({
                        selected: selected,
                        isClient: true,
                        categories: categoryFilters.categories.concat(
                            response.data.categories
                        ),
                    });
                })
                .catch(() => {
                    toast.error(
                        'Unable to load Client Service Provider categories'
                    );
                });
        }
    };

    const loadSelected = (items, property) => {
        const { location } = props;
        const result = [];

        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        if (urlFilters[property]) {
            urlFilters[property].split('-').forEach((item) => {
                const found = items.find((i) => item === i.name);

                if (typeof found !== 'undefined') {
                    result.push(items.find((i) => item === i.name));
                }
            });
        }

        return result;
    };

    const parseSelectedItems = (items, valueProperty) => {
        return items.map((item) => item[valueProperty]);
    };

    const onSubmit = (event) => {
        setIsLoading(true);
        setIsInit(false);
        setServiceProviders(null);
    };

    const onResult = (searchResults) => {
        setSearchResponse(searchResults);
        setServiceProviders(searchResults.companies || []);
        setIsLoading(false);
    };

    const isCategorySelected = () => {
        const { location } = props;
        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        return urlFilters.hasOwnProperty('category');
    };

    const updateSearchFilters = (filters) => {
        const { history, location } = props;
        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        urlFilters.page = 1;

        Object.keys(filters).forEach((filterName) => {
            urlFilters[filterName] = filters[filterName];
        });

        Location.setQueryStringFromObject(
            history,
            location,
            Filters.convertFilterDataToRequestData(urlFilters),
            false
        );
    };

    const updateSort = (value) => {
        const filterValue = sortFilters.find(
            (filter) => filter.value === value
        );
        updateSearchFilters({ order: filterValue.key });
    };

    const updateCategorySort = (value) => {
        const filterValue = categoryFilters.categories.find(
            (category) => category.name === value
        );
        setCategoryFilters({ ...categoryFilters, selected: filterValue });
        updateSearchFilters({ category: filterValue.id });
    };

    const getPlaceholderText = (type, data) => {
        let text = '';

        if (data.length > 0) {
            text = `${data.length} ${type}${
                data.length > 1 ? 's' : ''
            } selected`;
        } else {
            text = `Limit by ${type}?`;
        }

        return text;
    };

    const onServicesChange = (items) => {
        const { location } = props;

        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        setSearchFilters({
            ...searchFilters,
            ...urlFilters,
            services: parseSelectedItems(items, 'name'),
        });

        setSelectedServices(items);
    };

    const onRegionsChange = (items) => {
        const { location } = props;

        const urlFilters = Filters.getFilterStateFromUrl(
            location.search.slice(1),
            {}
        );

        setSearchFilters({
            ...searchFilters,
            ...urlFilters,
            regions: parseSelectedItems(items, 'name'),
        });

        setSelectedRegions(items);
    };

    const getFilters = () => {
        return (
            <>
                <FormGroup
                    label={'Services Offered'}
                    labelFor={'services'}
                    className={'bp3-inline form-fill multi-select-container'}
                >
                    <MultiSelectRenderer
                        id={'services'}
                        onRemoveItem={onServicesChange}
                        onItemSelect={onServicesChange}
                        file
                        items={services}
                        selectedItems={selectedServices}
                        valueProperty={'name'}
                        showTags={false}
                        placeholder={getPlaceholderText(
                            'Service',
                            selectedServices
                        )}
                    />
                </FormGroup>
                <FormGroup
                    label={'Regions Operated'}
                    labelFor={'regions'}
                    className={'bp3-inline form-fill multi-select-container'}
                >
                    <MultiSelectRenderer
                        id={'regions'}
                        onRemoveItem={onRegionsChange}
                        onItemSelect={onRegionsChange}
                        file
                        items={regions}
                        selectedItems={selectedRegions}
                        valueProperty={'name'}
                        placeholder={getPlaceholderText(
                            'Region',
                            selectedRegions
                        )}
                        showTags={false}
                    />
                </FormGroup>
            </>
        );
    };

    const onReset = async () => {
        const { history } = props;

        setSearchFilters({
            ...searchFilters,
            ...defaultFilters,
        });

        updateSearchFilters({ registeredCompanyName: null });
        setSelectedRegions([]);
        setSelectedServices([]);
        setServiceProviders([]);

        history.push('/service-providers');
        setIsInit(true);
    };

    const onResetFilters = async () => {
        updateSearchFilters({
            ...searchFilters,
            assessmentTypeCategory: null,
            professionalIndemnity: null,
            publicLiability: null,
            employersLiability: null,
            productsLiability: null,
            contractorAllRisk: null,
        });

        setClear(!clear); //trigger filter clear
    };

    const updateMultiLevelFilter = (options, key) => {
        const flatOptions = flattenMultiLevelSwitch(options);

        const activeOptionIds = flatOptions
            .filter((option) => option.checked)
            .map((option) => option.id)
            .filter((value) => !ssipFilterParentNames.includes(value));

        setSearchFilters({
            ...searchFilters,
            [key]: activeOptionIds.join(','),
        });

        updateSearchFilters({
            ...searchFilters,
            [key]: activeOptionIds.join(','),
        });
    };

    const onUpdateFilterSelect = (options, key) => {
        const activeOptionIds = options
            .filter((option) => option.value)
            .map((option) => option.id);

        setSearchFilters({
            ...searchFilters,
            [key]: activeOptionIds.join(','),
        });

        updateSearchFilters({
            ...searchFilters,
            [key]: activeOptionIds.join(','),
        });
    };

    const onInsuranceUpdate = (insurances) => {
        const insuranceFilters = {};

        for (const type in insurances) {
            const value = insurances[type];

            if (value === false) {
                insuranceFilters[type] = '';
            } else {
                insuranceFilters[type] = '' + value;
            }
        }

        updateSearchFilters({
            ...insuranceFilters,
            ...searchFilters,
        });
    };

    const flattenMultiLevelSwitch = (options) => {
        let flatOptions = [];

        options.forEach((row) => {
            const rows = flattenChildren(row);
            flatOptions = [...flatOptions, ...rows];
        });

        return flatOptions;
    };

    const renderLinkedFilter = () => {
        if (!isClientViewer()) {
            return null;
        }

        return (
            <LinkedFilter
                onUpdate={(options) =>
                    onUpdateFilterSelect(options, 'isLinked')
                }
                clear={clear}
            />
        );
    };

    return (
        <div
            className={
                'SearchPage ServiceProviderSearch ' + (!isInit ? 'reduced' : '')
            }
        >
            <div className="no-styling search-results-widget ">
                <SearchWidget
                    searchLink={{ method: 'GET', href: '/companies' }}
                    searchPlaceholder="Do you want to search by company name?"
                    searchLabel="Company Name"
                    searchKey="registeredCompanyName"
                    defaultFilters={searchFilters}
                    children={getFilters()}
                    onSubmit={onSubmit}
                    onResult={onResult}
                    onReset={onReset}
                />
            </div>

            <div className="no-styling search-results">
                {isInit ? null : (
                    <>
                        <div className="search-results-header">
                            <h1 className="page-title">
                                Service Provider Search
                                <ResultsCount count={searchResponse.count} />
                            </h1>

                            <div className="search-results-sort">
                                {categoryFilters.isClient && (
                                    <>
                                        <span>
                                            Supply Chain Requirements :{' '}
                                        </span>
                                        <HTMLSelect
                                            options={categoryFilters.categories.map(
                                                (c) => c.name
                                            )}
                                            onChange={(e) =>
                                                updateCategorySort(
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                categoryFilters.selected?.name
                                            }
                                        />
                                    </>
                                )}
                                <span>Sort By : </span>
                                <HTMLSelect
                                    options={sortFilters.map((f) => f.value)}
                                    onChange={(e) => updateSort(e.target.value)}
                                    defaultValue={searchOrderingDefaultValue}
                                />
                            </div>
                        </div>

                        <div className="search-results-filters">
                            <div className="filter-header">
                                <span>Filters</span>
                                <button
                                    className="clear-button success"
                                    onClick={onResetFilters}
                                >
                                    Reset
                                </button>
                            </div>
                            <div className="filter-body">
                                <div className="search-filter mb-4">
                                    <Accordion title="ASSESSMENTS">
                                        <MultiLevelSwitchArray
                                            fields={
                                                assessmentCategoryAndTypeOptions
                                            }
                                            onUpdate={(options) =>
                                                updateMultiLevelFilter(
                                                    options,
                                                    'assessmentCategoriesAndTypes'
                                                )
                                            }
                                            clear={clear}
                                        />
                                    </Accordion>
                                    {renderLinkedFilter()}
                                    <RatingFilter
                                        onUpdate={(options) =>
                                            onUpdateFilterSelect(
                                                options,
                                                'ratings'
                                            )
                                        }
                                        clear={clear}
                                    />
                                    <RedFlagFilter
                                        onUpdate={(options) =>
                                            onUpdateFilterSelect(
                                                options,
                                                'redFlagStatus'
                                            )
                                        }
                                        clear={clear}
                                    />
                                    <InsurancesFilter
                                        onUpdate={(options) =>
                                            onInsuranceUpdate(options)
                                        }
                                        clear={clear}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="search-results-body">
                            <div className="full-width">
                                {!serviceProviders ? (
                                    <div className="full-width">
                                        <LoadingIndicator />
                                    </div>
                                ) : serviceProviders.length ? (
                                    serviceProviders.map((company, index) => (
                                        <CompanyCard
                                            key={'company-card-' + index}
                                            company={company}
                                            showRequirements={isCategorySelected()}
                                            clientResponse={clientResponse}
                                            selectedCategory={
                                                categoryFilters.selected
                                            }
                                        />
                                    ))
                                ) : (
                                    <div className="no-results">
                                        No results found.
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="search-results-footer">
                            {isLoading ||
                            (searchResponse &&
                                !(
                                    searchResponse.count > searchResponse.limit
                                )) ? null : (
                                <Paginator
                                    page={searchResponse.page}
                                    count={searchResponse.count}
                                    limit={searchResponse.limit}
                                    onPage={updateSearchFilters}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(Search);
