import React, { useEffect, useReducer } from 'react';
import { useSelector, useStore } from 'react-redux';

import { setRootClient } from 'action';
import { SuggestRenderer } from 'components/elements';
import { ClientData, ViewAsClientProps } from 'interface';
import { ClientService } from 'service';
import './index.scss';

interface ClientFilter {
    hasElogs?: boolean;
}

const ViewAsClient = (props: ViewAsClientProps) => {
    const store = useStore();
    const root = useSelector((state: any) => state.root);
    const { onlyLoadCafmClients } = props;

    const [state, setState] = useReducer(
        (state: any, edits: any) => ({ ...state, ...edits }),
        {
            clients: [],
            selectedClient: undefined,
        }
    );

    useEffect(() => {
        loadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlyLoadCafmClients]);

    useEffect(() => {
        setState({ selectedClient: root.client });
    }, [root.client]);

    const loadList = async () => {
        let params: ClientFilter = {};

        if (typeof onlyLoadCafmClients === 'boolean') {
            params.hasElogs = onlyLoadCafmClients;
        }

        const clientList = await ClientService.loadClientList(params);

        if (clientList && clientList.clients.length > 0) {
            const clients = [
                {
                    id: 'all-clients',
                    name: 'All Clients',
                    _links: {
                        'supply-chain-credit-health-check': {
                            href: '/companies/credit-health-check',
                            method: 'GET',
                        },
                        'supply-chain-sp-counts': {
                            href: '/companies/assessment-counts',
                            method: 'GET',
                        },
                    },
                } as ClientData,
                ...clientList.clients,
            ];
            setState({ clients });
        }
    };

    const selectClient = async (
        client: ClientData,
        event: React.ChangeEvent
    ) => {
        setState({ selectedClient: client });
        store.dispatch(setRootClient(client));
        typeof props.onClientSelect === 'function' &&
            props.onClientSelect(client, event);

        if (window.location.hash.includes('#/supply-chain/elogs-cafm-stats/list')) {
            window.location.replace(`#/supply-chain/elogs-cafm-stats/list`);
            window.location.reload();
        }
    };

    if (!state.clients.length) {
        return <></>;
    }

    return (
        <>
            <section className="floating-bottom-banner view-as-client">
                <section className="inner">
                    <span className="title">Viewing as Client: </span>
                    <div className="search-wrap">
                        <SuggestRenderer
                            id="parent"
                            onItemSelect={selectClient}
                            items={state.clients}
                            defaultItem={state.selectedClient}
                            isRequired={false}
                            popoverProps={{ position: 'top' }}
                            placeholder={'Select a Client'}
                        />
                    </div>
                </section>
            </section>
        </>
    );
};

export default ViewAsClient;
