import { Link, Redirect, Switch } from 'react-router-dom';
import { Tabs, Tab } from '@blueprintjs/core';

import { Routing } from 'service';
import { Icon, ProtectedRoute } from 'components/elements';
import IntegrationsList from 'components/admin/integrations/IntegrationsList';
import IntegationsAdd from 'components/admin/integrations/IntegationsAdd';

const IntegrationsTabs = (props: any) => {
    const { match, location } = props;
    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div>
            <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                <Tab id={'/elogbooks'}>
                    <Link to={`${match.url}/elogbooks`}>
                        <Icon icon="building" />
                        Elogs
                    </Link>
                </Tab>
            </Tabs>

            <div className="TabContent">
                <Switch>
                    <ProtectedRoute
                        path={`${match.url}/elogbooks/add`}
                        component={IntegationsAdd}
                        exact
                    />
                    <ProtectedRoute
                        path={`${match.url}/elogbooks`}
                        component={IntegrationsList}
                        integrationType="Elogbooks"
                    />
                    <Redirect to={`${match.url}/elogbooks`} />
                </Switch>
            </div>
        </div>
    );
};

export default IntegrationsTabs;
