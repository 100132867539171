import { AxiosResponse } from 'axios';
import { HTTP, Response } from 'service';

import {
    ORGANISATION_TYPE_COMPANY_LTD_BY_GUARANTEE,
    ORGANISATION_TYPE_LLP,
    ORGANISATION_TYPE_LTD,
    ORGANISATION_TYPE_OTHER,
    ORGANISATION_TYPE_PARTNERSHIP,
    ORGANISATION_TYPE_PUBLIC_LTD_COMPANY,
} from 'constants/company';
import { ListCollection } from 'interface';
import { CampaignUpdatesListFilter, InviteeStatus } from 'interface/Client/Campaign';
import {
    Company,
    CompanyDashboardStats,
    CompanyFilter,
    CompanyListData
} from 'interface/Company';

class CompanyService {
    static loadList: (
        filter?: Partial<CompanyFilter>
    ) => Promise<CompanyListData> = async (filter?: Partial<CompanyFilter>) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            '/companies',
            filter ? filter : {},
            {},
            'Unable to load assessment list list'
        );
        if (response) {
            return response.data as CompanyListData;
        } else {
            return {
                count: 0,
                companies: [],
            } as CompanyListData;
        }
    };

    static loadDashboardStats: (
        dashboardLink: string
    ) => Promise<CompanyDashboardStats> = async (dashboardLink: string) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            dashboardLink,
            { datagroup: 'details' },
            {},
            'Unable to load dashboard stats'
        );
        if (response) {
            return response.data as CompanyDashboardStats;
        } else {
            return {} as CompanyDashboardStats;
        }
    };

    static createCompanyFilter(): CompanyFilter {
        return {
            registeredCompanyName: '',
            limit: 50,
            order: 'registeredCompanyName',
        };
    }

    static getProsureOrganisationType(companiesHouseType: string): string {
        switch (companiesHouseType) {
            case 'private-limited-guarant-nsc-limited-exemption':
            case 'private-limited-guarant-nsc':
                return ORGANISATION_TYPE_COMPANY_LTD_BY_GUARANTEE;
            case 'ltd':
                return ORGANISATION_TYPE_LTD;
            case 'llp':
                return ORGANISATION_TYPE_LLP;
            case 'limited-partnership':
                return ORGANISATION_TYPE_PARTNERSHIP;
            case 'plc':
                return ORGANISATION_TYPE_PUBLIC_LTD_COMPANY;
            default:
                return ORGANISATION_TYPE_OTHER;
        }
    }

    static getInviteeStatuses = async (
        company: Company,
        filter: CampaignUpdatesListFilter
    ): Promise<ListCollection<InviteeStatus> | void> => {
        const url = Response.getLink(company, 'invitee-statuses');

        if (!url) {
            return Promise.resolve(void 0);
        }

        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            { datagroup: 'details', ...filter },
            {},
            'Unable to load Invitee Status data'
        );

        if (response) {
            return Promise.resolve({
                ...response.data,
                data: response.data.updates,
            });
        }
        return Promise.resolve(void 0);
    };

    static postCompanyInviteeStatus = async (
        company: Company,
        data: any 
    ): Promise<ListCollection<InviteeStatus> | void> => {
        const response: AxiosResponse | void = await HTTP.action(
            'post',
            `/company/${company?.id}/invitee-statuses`,
            data,
            {},
            'Unable to create update'
        );

        if (response) {
            return Promise.resolve({
                ...response.data,
                data: response.data.updates,
            });
        }

        return Promise.resolve(void 0);
    };
}

export default CompanyService;
