import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import UserList from 'components/company/users/UserList';
import UserAdd from 'components/company/users/UserAdd';
import ProtectedRoute from 'components/elements/ProtectedRoute';

// const UserList = () => { return 'User List'; }
// const UserAdd = () => { return 'User Add'; }

class Users extends Component
{
    render()
    {
        const { companyResponse, match } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <ProtectedRoute path={`${match.url}`} component={ UserList } companyResponse={ companyResponse } exact />
                    <ProtectedRoute path={`${match.url}/add`} component={ UserAdd } companyResponse={ companyResponse } exact />
                </Switch>
            </div>
        );
    }
}

export default Users;
