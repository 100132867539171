import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import {
    SyntheticEvent,
    MouseEvent,
    ComponentProps,
    useState,
    useEffect,
    FormEvent,
    ChangeEvent,
} from 'react';
import PropTypes from 'prop-types';

import { HTMLSelect } from 'components/elements';
import { AssessmentTypeInstanceData, Option, UserData } from 'interface';
import { InstanceService, Response, UserService } from 'service';
import { ROLE_OPTIONS } from 'constants/role';
import { translate } from 'service/Translator';
import { NONE_SELECTED_OPTION } from 'constants/none-selected';

interface ChangeOwnershipProps extends ComponentProps<any> {
    onCancel: (event: SyntheticEvent) => void;
    afterSubmit: (event: SyntheticEvent) => void;
    onSubmit: (event: SyntheticEvent) => void;
    onClose: (event: SyntheticEvent) => void;
    instance: AssessmentTypeInstanceData;
    isOpen: boolean;
}

const ChangeOwnershipDialog = (props: Partial<ChangeOwnershipProps>) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const [isSubmitting, setIsSubmitting] = useState(props.isSubmitting);
    const [user, setUser]: [UserData?, Function?] = useState();
    const [users, setUsers]: [UserData[], Function?] = useState([]);
    const [userOptions, setUserOptions]: [Option[], Function?] = useState([]);
    const [errorMessage, setErrorMessage]: [string?, Function?] = useState();

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUsers = async () => {
        const response = await UserService.getUsers({
            roles: [
                'ROLE_ADMINISTRATOR',
                'ROLE_PROSURE_CLIENT_SERVICES',
                'ROLE_REGISTRATIONS_RENEWALS',
                'ROLE_ASSESSMENT_ASSESSOR',
            ],
            limit: 999,
        });

        const userData = response?.data.users as UserData[];
        response && setUsers(userData);
        // also update select list
        const userOptionsInit = userData.map((user) => {
            return {
                label: [
                    user.forename,
                    user.surname,
                    '(' + translate(user.roles[0], ROLE_OPTIONS) + ')',
                ].join(' '),
                value: user.id,
            };
        });
        setUserOptions([NONE_SELECTED_OPTION, ...userOptionsInit]);

        const existingAssessor = Response.getLinkAttribute(
            props.instance,
            'last-assessed-by',
            'id'
        );
        if (!!existingAssessor) {
            setUser(
                response?.data.users.find(
                    (user: UserData) => user.id === existingAssessor
                )
            );
        }
    };

    const onCancel = (event: MouseEvent) => {
        setIsOpen(false);
        typeof props.onCancel === 'function' && props.onCancel(event);
        typeof props.onClose === 'function' && props.onClose(event);
    };

    const onSubmit = async (event: FormEvent) => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        setIsOpen(false);
        typeof props.onSubmit === 'function' && props.onSubmit(event);

        const response = await doSubmit(event);
        if (!response) {
            return false;
        }
        typeof props.afterSubmit === 'function' && props.afterSubmit(event);
        typeof props.onClose === 'function' && props.onClose(event);
    };

    const doSubmit = async (event: FormEvent) => {
        if (!user || !props.instance) {
            setIsOpen(true);
            setIsSubmitting(false);
            setErrorMessage('Please select a user for submission!');
            return false;
        }

        return InstanceService.adminChangeOwnership(props.instance, user, () =>
            setIsSubmitting(false)
        );
    };

    const changeUser = (event: ChangeEvent) => {
        const user = users.find(
            (user) => user.id === (event.target as HTMLSelectElement).value
        );
        setUser(user);
    };

    return (
        <>
            <Dialog
                className="confirmation-dialog"
                onClose={(event) => {
                    typeof props.onClose === 'function' && props.onClose(event);
                }}
                title="Change Assessor Ownership - Select Person"
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={true}
                isOpen={isOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div className="mb-3">
                        <p>Please select a user to change ownership to</p>
                        {errorMessage && (
                            <span className="error">{errorMessage}</span>
                        )}
                    </div>
                    <HTMLSelect
                        disabled={isSubmitting}
                        id="type"
                        fill={true}
                        defaultValue={Response.getLinkAttribute(
                            props.instance,
                            'last-assessed-by',
                            'id'
                        )}
                        onChange={changeUser}
                        options={userOptions}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className="dialog-footer-buttons">
                        <Button type="button" onClick={onCancel}>
                            {'Cancel'}
                        </Button>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                                type="button"
                                intent={Intent.SUCCESS}
                                disabled={isSubmitting}
                                onClick={onSubmit}
                            >
                                {'Confirm'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

ChangeOwnershipDialog.propTypes = {
    onCancel: PropTypes.func,
    afterSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    instance: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

ChangeOwnershipDialog.defaultProps = {
    isOpen: false,
};

export default ChangeOwnershipDialog;
