import { H2 } from '@blueprintjs/core';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import DashboardCard from 'components/dashboard/DashboardCard';
import { Loading } from 'components/elements/wrappers';
import { HTTP, Response } from 'service';

import { Link } from 'react-router-dom';
import './client-dashboard.scss';

const mapStateToProps = (state) => {
    return {
        rootResponse: state.root.response,
    };
};
const mapDispatchToProps = {};

class ClientDashboard extends Component {
    clientInterval;
    constructor(props) {
        super(props);

        this.state = {
            supplyChainRatingCount: {
                meetsAll: '-',
                expiring: '-',
                below: '-',
            },
            pendingSPCount: 0,
            isInitialising: false,
            isLoadingActiveSP: false,
            isLoadingPendingSP: false,
        };
    }

    componentDidMount() {
        this.clientInterval = window.setInterval(() => {
            this.loadPendingSP();
            this.loadSupplyChainRequirementCounts();
        }, 3600000);

        this.setState({ isInitialising: true });
        this.loadPendingSP();
        this.loadSupplyChainRequirementCounts();
        this.setState({ isInitialising: false });
    }

    componentWillUnmount() {
        window.clearInterval(this.clientInterval);
    }

    render() {
        const {
            pendingSPCount,
            isLoadingPendingSP,
            isLoadingActiveSP,
            isInitialising,
        } = this.state;

        return (
            <div className="DashboardPage client">
                <Loading isLoading={isInitialising}>
                    <DashboardCard
                        isLarge
                        isLoading={isLoadingActiveSP}
                        title="Active Service Providers"
                        moreText="Go to my Supply Chain..."
                        link="/supply-chain/list"
                    >
                        {this.renderSupplyChainTrafficLights()}
                    </DashboardCard>
                    <DashboardCard
                        isLoading={isLoadingPendingSP}
                        title="Pending Service Providers"
                        moreText="Go to my Supply Chain..."
                        link="/supply-chain/list?supplyChain=pending"
                        value={pendingSPCount}
                    />
                </Loading>
            </div>
        );
    }

    loadSupplyChainRequirementCounts = async () => {
        if (this.state.isLoadingActiveSP) {
            return;
        }

        this.setState({ isLoadingActiveSP: true });
        const { rootResponse } = this.props;
        const clientId = rootResponse._links.client.id;

        try {
            const clientResponse = await HTTP.get(`/clients/${clientId}`, {
                datagroup: 'details',
            });
            const SupplyChainRequirements = await HTTP.get(
                Response.getLink(
                    clientResponse.data,
                    'supply-chain-requirement-counts'
                ),
                {}
            );
            this.setState({
                supplyChainRatingCount: SupplyChainRequirements.data,
                isLoadingActiveSP: false,
            });
        } catch {
            toast.error('Unable to load Supply Chain Ratings');
        }
    };

    renderSupplyChainTrafficLights = () => {
        return (
            <ul className="supply-chain-rating">
                <li className="rating-row all-met">
                    <H2 className="rating">
                        <Link
                            className='all-met'
                            to="/supply-chain/list?clientRequirement=SUCCESS"
                        >
                            {this.state.supplyChainRatingCount.meetsAll}
                        </Link>
                    </H2>
                    <span className="rating-text">
                        Meets Client Requirements
                    </span>
                </li>
                <li className="rating-row expiring">
                    <H2 className="rating">
                        <Link
                            className="expiring"
                            to="/supply-chain/list?clientRequirement=EXPIRING"
                        >
                            {this.state.supplyChainRatingCount.expiring}
                        </Link>
                    </H2>
                    <span className="rating-text">
                        Requirements Expiring Soon
                    </span>
                </li>
                <li className="rating-row below">
                    <H2 className="rating">
                        <Link 
                            className="below"
                            to="/supply-chain/list?clientRequirement=BELOW"
                        >
                            {this.state.supplyChainRatingCount.below}
                        </Link>
                    </H2>
                    <span className="rating-text">
                        Below Client Requirements
                    </span>
                </li>
            </ul>
        );
    };

    loadPendingSP = () => {
        if (this.state.isLoadingPendingSP) {
            return;
        }

        this.setState({ isLoadingPendingSP: true });

        HTTP.get('/supply-chain', { status: 'status_pending', limit: 1 }).then(
            (response) => {
                if (response) {
                    this.setState({
                        isLoadingPendingSP: false,
                        pendingSPCount: response.data.count,
                    });

                    return true;
                }

                toast.error('Unable to fetch Service Provider data');
                this.setState({ isLoadingPendingSP: false });

                return false;
            }
        );
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
