import { H1 } from '@blueprintjs/core';
import { ButtonLink, Icon, Tel } from 'components/elements';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Response } from 'service';

import Address from 'components/clients/Address';

class ClientInfo extends Component {
    static propTypes = {
        clientResponse: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    getEditButton() {
        const { clientResponse, match, isClientBio } = this.props;

        if (isClientBio) {
            return null;
        }

        return Response.getLink(clientResponse, 'edit') === null
            ? null 
            : (
                <ButtonLink
                    type="button"
                    to={`${match.url}/edit`}
                    intent="primary"
                    className="float-right"
                >
                    <Icon icon="pencil" />
                    Edit
                </ButtonLink>
            );
    }

    render() {
        const { clientResponse } = this.props;

        return (
            <div className="ClientInfo">
                <H1 className="clearfix">
                    Info
                    {this.getEditButton()}
                </H1>

                <dl className="horizontal">
                    <dt>Phone Number</dt>
                    <dd>
                        <Tel tel={clientResponse.phone || ''} />
                    </dd>
                    
                    <dt>Type of Organisation</dt>
                    <dd>{clientResponse.organisationType}</dd>
                    <dt>Customer Code</dt>
                    <dd>{clientResponse.customerCode}</dd>
                    {clientResponse._links.logo ? (
                        <>
                            <dt>Company Logo</dt>
                            <dd>
                                <img
                                    className="badge-img"
                                    alt={clientResponse._links.logo.title}
                                    title={clientResponse._links.logo.title}
                                    src={
                                        process.env.REACT_APP_BASE_URL +
                                        clientResponse._links.logo.publicUrl
                                    }
                                ></img>
                            </dd>
                        </>
                    ) : null}
                </dl>

                <Address
                    title="Registered Address"
                    addressObject={clientResponse.registeredAddress}
                />

                <Address
                    title="Invoice Address"
                    addressObject={clientResponse.invoiceAddress}
                />
            </div>
        );
    }
}

export default ClientInfo;
