import { useEffect, useState } from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs } from '@blueprintjs/core';

import { Response, InstanceService } from 'service';
import {
    clearChanges,
    clearErrors,
    loadAssessmentData,
} from 'action/assessments';
import { ProtectedRoute } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import ServicesBanner from 'components/assessments/servicesBanner/ServicesBanner';
import AssessmentTypeInstanceHistory from 'components/assessments/tabs/history/AssessmentTypeInstanceHistory';
import Comments from 'components/assessments/tabs/comments/Comments';
import QuestionsForm from 'components/assessments/tabs/questions/QuestionsForm';
import { isAssessorReviewer } from 'helpers/helpers';

const AssessmentTypeAccreditation = (props) => {
    // load props/params
    const { match, mode } = props;
    const assessmentInstanceId = match.params.id;
    const [commentsData, setCommentsData] = useState([]);

    // add hooks to redux stores
    const rootResponse = useSelector((state) => state.root.response);
    const assessmentLoadedState = useSelector((state) => state.assessments);

    const dispatch = useDispatch();

    // on page load, reset saved state, load state from api
    useEffect(() => {
        reloadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getComments = async () => {
        const comments = await InstanceService.getComments(
            assessmentTypeInstance
        );
        setCommentsData(comments);
    };

    // reloadData method passed to submit methods to reload form
    const reloadData = () => {
        dispatch(clearErrors());
        dispatch(clearChanges());
        dispatch(loadAssessmentData(assessmentInstanceId, rootResponse, mode));
        getComments();
    };

    let { topicCollection, assessmentTypeInstance, isLoading } = assessmentLoadedState;

    return (
        <div className="page">
            <div className="page-wrapper no-styling AccreditationAnswers">
                <Loading isLoading={isLoading}>
                    <NoResultsFound count={topicCollection?.count}>
                        <ServicesBanner
                            assessmentTypeInstance={assessmentTypeInstance}
                        />
                        <div className="inner">
                            <div className="page-header">
                                <h1 className="page-title">
                                    {Response.getLinkAttribute(
                                        assessmentTypeInstance,
                                        'assessment-type',
                                        'name'
                                    )}
                                    {isAssessorReviewer() &&
                                        ' - ' +
                                            Response.getLinkAttribute(
                                                assessmentTypeInstance,
                                                'assessment-type',
                                                'version'
                                            )}
                                </h1>
                            </div>
                            {mode === 'review' && (
                                <div className="info pb-4">
                                    <div>
                                        Company name:{' '}
                                        {Response.getLinkAttribute(
                                            assessmentTypeInstance,
                                            'company',
                                            'registeredCompanyName'
                                        )}
                                    </div>
                                    <div>
                                        Company size:{' '}
                                        {Response.getLinkAttribute(
                                            assessmentTypeInstance,
                                            'company',
                                            'organisationSize'
                                        )}
                                    </div>
                                </div>
                            )}
                            <Tabs
                                renderActiveTabPanelOnly={true}
                                className="parent"
                            >
                                <Tab id={'/questions'}>
                                    <Link to={`${match.url}/questions`}>
                                        Question Topics
                                    </Link>
                                </Tab>
                                {Response.getLink(
                                    assessmentTypeInstance,
                                    'events'
                                ) && (
                                    <Tab id={'/history'}>
                                        <Link to={`${match.url}/history`}>
                                            History
                                        </Link>
                                    </Tab>
                                )}
                                {(commentsData.length > 0 ||
                                    Response.getLink(
                                        assessmentTypeInstance,
                                        'add-comments'
                                    )) && (
                                    <Tab id={'/comments'}>
                                        <Link to={`${match.url}/comments`}>
                                            Additional Comments
                                        </Link>
                                    </Tab>
                                )}
                            </Tabs>
                            <div className="TabContent">
                                <Switch>
                                    <ProtectedRoute
                                        path={`${match.url}/questions`}
                                        component={QuestionsForm}
                                        mode={mode}
                                        reloadData={reloadData}
                                    />
                                    <ProtectedRoute
                                        path={`${match.url}/history`}
                                        component={
                                            AssessmentTypeInstanceHistory
                                        }
                                        assessmentTypeInstanceResponse={
                                            assessmentTypeInstance
                                        }
                                    />
                                    <ProtectedRoute
                                        path={`${match.url}/comments`}
                                        component={Comments}
                                        assessmentTypeInstance={
                                            assessmentTypeInstance
                                        }
                                    />
                                    <Redirect
                                        to={`${match.url}/questions`}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </NoResultsFound>
                </Loading>
            </div>
        </div>
    );
};

export default AssessmentTypeAccreditation;
