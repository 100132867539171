import { isInteger } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
    clearErrors,
    setAssessmentSubmitted,
    setErrors,
    setSortedQuestions,
} from 'action';
import { ASSESSMENT_TYPE_MILESTONES } from 'constants/assessmentTypeMilestones';
import {
    DTS_QUESTION_CERTIFICATION_NUMBER,
    DTS_QUESTION_SERVICES_UNDERTAKEN,
    DTS_QUESTION_SSIP_LIST,
    DTS_QUESTION_SSIP_SCHEME_OPERATOR,
    DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
} from 'constants/dtsQuestionKeys';
import { getDefaultAnswerObject } from 'helpers/helpers';
import {
    ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
    AnswerData,
    AssessmentErrorCollection,
    AssessmentFormError,
    AssessmentStateData,
    AssessmentTypeInstanceData,
    QuestionData,
    QuestionInstanceData,
    ReviewData,
    SortedTopic,
    TopicData,
} from 'interface';
import { HTTP, Response } from 'service';
import store from 'store';

export const checkIsSSIPDTS = () => {
    const { assessmentTypeInstance }: AssessmentStateData =
        store.getState().assessments;
    return Response.getLinkAttribute(
        assessmentTypeInstance,
        'assessment-type',
        'isSSIPDTS'
    );
};

export const checkIsCaseByCase = (): string | false => {
    const { assessmentTypeInstance }: AssessmentStateData =
        store.getState().assessments;
    return Response.getLinkAttribute(
        assessmentTypeInstance,
        'assessment-type',
        'isCaseByCase'
    );
};

export const checkIsProsureAssessorReviewerRequired = (): string | false => {
    const { assessmentTypeInstance }: AssessmentStateData =
        store.getState().assessments;
    return Response.getLinkAttribute(
        assessmentTypeInstance,
        'assessment-type',
        'isProsureAssessorReviewerRequired'
    );
};

export const checkIsReviewDisabled = (): boolean => {
    const { assessmentTypeInstance }: AssessmentStateData =
        store.getState().assessments;
    const rootResponse = store.getState().root.response;

    return !(
        (assessmentTypeInstance._links['assess-answers'] &&
            Response.getLinkAttribute(
                assessmentTypeInstance,
                'last-assessed-by',
                'id'
            ) === rootResponse.user.id) ||
        (assessmentTypeInstance._links['review-answers'] &&
            Response.getLinkAttribute(
                assessmentTypeInstance,
                'last-reviewed-by',
                'id'
            ) === rootResponse.user.id)
    );
};

export const getAssessmentStatus = (
    status:
        | 'status_purchased'
        | 'status_in_progress'
        | 'status_submitted_awaiting_assessor'
        | 'status_submitted_returned'
        | 'status_pending_awaiting_reviewer'
        | 'status_completed'
        | 'status_completed_expired'
        | 'status_refunded'
        | 'status_auto_returned'
): string | false => {
    return (
        ASSESSMENT_TYPE_MILESTONES.hasOwnProperty(status) &&
        ASSESSMENT_TYPE_MILESTONES[status]
    );
};

export const sortQuestionsByTopic = (
    assessmentState: AssessmentStateData
): SortedTopic[] => {
    const { topicCollection, questionsCollection } = assessmentState;
    let sortedTopics: SortedTopic[] = [];
    questionsCollection?.questions?.forEach((question) => {
        const topic = topicCollection.topics.find(
            (topic) => topic.id === question.topicId
        );
        const existing = sortedTopics.find(
            (sortedTopic) => sortedTopic.topic.id === topic?.id
        );
        if (existing) {
            existing.sortedQuestions.push(question);
        } else {
            sortedTopics.push({
                topic,
                sortedQuestions: [question],
                isOpen: false,
            } as SortedTopic);
        }
    });
    return sortedTopics;
};

export const updateSortedTopic = (
    changes: Partial<SortedTopic>,
    topic: SortedTopic
): void => {
    const { sortedQuestions }: AssessmentStateData =
        store.getState().assessments;
    store.dispatch(
        setSortedQuestions(
            sortedQuestions.map((sortedTopic, index) =>
                index === sortedQuestions.indexOf(topic)
                    ? { ...sortedTopic, ...changes }
                    : sortedTopic
            )
        )
    );
};

export const getAnswerOrNew = (question: QuestionData): AnswerData => {
    const {
        answersCollection,
        assessmentTypeInstance,
        changes,
    }: AssessmentStateData = store.getState().assessments;
    return (
        changes[question.id] ??
        answersCollection.answers.find(
            (answer) =>
                Response.getLink(answer, 'question') ===
                Response.getLink(question, 'self')
        ) ??
        (
            getDefaultAnswerObject(assessmentTypeInstance, question, void 0, {
                value: '',
            }) as { [id: string]: AnswerData }
        )[question.id]
    );
};

export const getAssessmentAnswer = (key: keyof AssessmentTypeInstanceData) => {
    const { assessmentTypeInstance, changes }: AssessmentStateData =
        store.getState().assessments;
    return changes[key] ?? assessmentTypeInstance[key];
};

const getAnswers = (): {
    answers: { [key: string]: AnswerData };
    assessmentAnswers: { [key: string]: string };
} => {
    const assessmentState: AssessmentStateData = store.getState().assessments;
    const { questionsCollection } = assessmentState;

    let answers: { [key: string]: AnswerData } = {};
    let assessmentAnswers: { [key: string]: string } = {};

    // get answers for all questions, answered or not
    questionsCollection.questions.forEach((question) => {
        answers[question.question.id] = getAnswerOrNew(question.question);
    });

    if (checkIsSSIPDTS()) {
        DTS_QUESTION_SSIP_LIST.forEach((key) => {
            assessmentAnswers[key] = getAssessmentAnswer(
                key as keyof AssessmentTypeInstanceData
            );
        });
    }

    return { answers, assessmentAnswers };
};

/**
 * Submits the assessment form, with validation if necessary, and returns true if reload is necessary
 */
export const handleAssessmentSubmit = async (
    saveType?: string,
    afterSubmit?: Function
): Promise<boolean> => {
    const assessmentState: AssessmentStateData = store.getState().assessments;
    const { assessmentTypeInstance } = assessmentState;
    const { answers, assessmentAnswers } = getAnswers();

    if (checkIsSSIPDTS()) {
        DTS_QUESTION_SSIP_LIST.forEach((key) => {
            assessmentAnswers[key] = getAssessmentAnswer(
                key as keyof AssessmentTypeInstanceData
            );
        });
    }

    // do validation
    if (saveType === 'submit') {
        store.dispatch(clearErrors());
        const errors = getErrors(answers, assessmentAnswers);
        store.dispatch(setErrors(errors));

        store.dispatch(setAssessmentSubmitted());

        if (errors.length) {
            toast.error('Please correct the errors. Progress were not saved.');

            return false;
        }
    }

    const response = await HTTP.action(
        'patch',
        Response.getLink(assessmentTypeInstance, 'update-answers'),
        { answers, ...assessmentAnswers, saveType },
        {},
        'Unable to save progress'
    );

    if (response) {
        toast.success('Changes saved');
        //submit callback
        saveType === 'submit' &&
            typeof afterSubmit === 'function' &&
            afterSubmit();

        return saveType === 'submit'; // only reload if is submit
    }
    return false;
};

/**
 * Submits the assessment review form, with validation if necessary, and returns true if reload is necessary
 */
export const handleAssessmentReviewsSubmit = async (
    saveType?: string,
    afterSubmit?: Function
): Promise<boolean> => {
    const assessmentState: AssessmentStateData = store.getState().assessments;
    const { assessmentTypeInstance, reviewChanges } = assessmentState;
    const { answers, assessmentAnswers } = getAnswers();

    const patchUrl = assessmentTypeInstance._links['assess-answers']
        ? 'assess-answers'
        : 'review-answers';
    let otherReviewData: { [key: string]: string } = {};

    if (saveType === 'submit' && !validateAssessmentReviews()) {
        return false;
    }

    // case by case assessments
    if (checkIsCaseByCase()) {
        otherReviewData['validFrom'] = getAssessmentAnswer('validFrom');
        otherReviewData['expiryDate'] = getAssessmentAnswer('expiryDate');
    }

    const response = await HTTP.action(
        'patch',
        Response.getLink(assessmentTypeInstance, patchUrl),
        {
            reviews: reviewChanges,
            ...otherReviewData,
            saveType,
            answers,
            ...assessmentAnswers,
        },
        {},
        'Unable to save progress'
    );

    if (response) {
        toast.success('Changes saved');
        //submit callback
        saveType === 'submit' &&
            typeof afterSubmit === 'function' &&
            afterSubmit();

        // don't reload on save, only on submit
        return saveType === 'submit';
    }
    return false;
};

export const validateAssessmentReviews = (): boolean => {
    store.dispatch(clearErrors());
    const errors = getReviewErrors();
    store.dispatch(setErrors(errors));
    if (errors.length) {
        toast.error('Please correct the errors. Progress were not saved.');

        return false;
    }
    return true;
};

/**
 * Sets errors into the answer object & returns questions and answers that are in error
 */
export const getErrors = (
    answers: { [key: string]: AnswerData },
    assessmentAnswers: { [key: string]: string }
): AssessmentErrorCollection[] => {
    const { questionsCollection, topicCollection }: AssessmentStateData =
        store.getState().assessments;

    let matchedAnswers: AssessmentErrorCollection[] =
        questionsCollection.questions
            // filter questions that requires validation
            .filter((question) => {
                return (
                    question.question.isRequired ||
                    question.question.expiryRequirement === 2 ||
                    question.question.numberRequirement === 2 ||
                    question.question.maxValue ||
                    question.question.minValue ||
                    question.question.maxLength ||
                    question.question.minLength ||
                    question.question.maxDateOffset ||
                    question.question.minDateOffset
                );
            })
            //get answer
            .map((question) => {
                const topic =
                    topicCollection.topics.find(
                        (topic) => topic.id === question.topicId
                    ) ?? ({ id: 'misc', name: 'misc' } as TopicData);
                //Note: misc should not render, above fallback is just filler to satisfy strong typing
                return {
                    topic,
                    question: question.question,
                    answer: answers[question.question.id],
                    errors: [],
                };
            });

    // add DTS questions if SSIP DTS
    if (checkIsSSIPDTS()) {
        matchedAnswers = matchedAnswers.concat([
            {
                topic: { id: 'dts', name: 'Deem-to-Satisfy' } as TopicData,
                question: {
                    id: DTS_QUESTION_SERVICES_UNDERTAKEN,
                    isRequired: true,
                    summary: 'Services Undertaken',
                } as QuestionData,
                answer: {
                    value:
                        assessmentAnswers[DTS_QUESTION_SERVICES_UNDERTAKEN] ??
                        '',
                } as AnswerData,
                errors: [],
            },
            {
                topic: { id: 'dts', name: 'Deem-to-Satisfy' } as TopicData,
                question: {
                    id: DTS_QUESTION_SSIP_SCOPE_CATEGORIES,
                    isRequired: true,
                    summary: 'SSIP Scope Category',
                } as QuestionData,
                answer: {
                    value:
                        assessmentAnswers[DTS_QUESTION_SSIP_SCOPE_CATEGORIES] ??
                        '',
                } as AnswerData,
                errors: [],
            },
            {
                topic: { id: 'dts', name: 'Deem-to-Satisfy' } as TopicData,
                question: {
                    id: DTS_QUESTION_CERTIFICATION_NUMBER,
                    isRequired: false,
                    summary: 'Assessment/Certification Number',
                } as QuestionData,
                answer: {
                    value:
                        assessmentAnswers[DTS_QUESTION_CERTIFICATION_NUMBER] ??
                        '',
                } as AnswerData,
                errors: [],
            },
            {
                topic: { id: 'dts', name: 'Deem-to-Satisfy' } as TopicData,
                question: {
                    id: DTS_QUESTION_SSIP_SCHEME_OPERATOR,
                    isRequired: true,
                    summary: 'SSIP Scheme Operator',
                } as QuestionData,
                answer: {
                    value:
                        assessmentAnswers[DTS_QUESTION_SSIP_SCHEME_OPERATOR] ??
                        '',
                } as AnswerData,
                errors: [],
            },
        ]);
    }

    return matchedAnswers.filter((matchedAnswer) => {
        let { question } = matchedAnswer;

        // if parent conditional-question is empty or wrong, ignore this answer for checking
        if (Response.getLink(question, 'conditional-question')) {
            let id = question._links['conditional-question'].id;

            if (typeof answers[id] === 'undefined') {
                return false;
            }

            if (
                question.conditionRequirement.toString() !==
                answers[id].value.toString()
            ) {
                return false;
            }
        }
        return calculateError(matchedAnswer);
    });
};

export const calculateError = (
    matchedAnswer: AssessmentErrorCollection
): boolean => {
    let { question, answer } = matchedAnswer;
    // add error messages directly on answer object
    answer.errorMessages = [];
    // isValid is reversed at the end so matchedQuestions in error is returned by filter
    let isValid = true;
    let toCheck;

    if (question.isRequired) {
        // note: files have an empty "value" prop (unless numberRequirement is filled)
        if (question.type === 'file') {
            toCheck = answer.files && answer.files.length;
            if (!toCheck) {
                answer.errorMessages.push('This field is required');
                matchedAnswer.errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'This field is required',
                });
            }
            isValid = isValid && !!toCheck;
        } else if (question.type === 'trend') {
            toCheck =
                answer.value &&
                typeof answer.value !== 'string' &&
                answer.value.yearBeforeLast &&
                answer.value.yearBeforeLast.length;
            if (!toCheck) {
                answer.errorMessages.push('Year Before Last is required');
                matchedAnswer.errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'Year Before Last is required',
                });
            }
            isValid = isValid && !!toCheck;

            toCheck =
                answer.value &&
                typeof answer.value !== 'string' &&
                answer.value.lastYear &&
                answer.value.lastYear.length;
            if (!toCheck) {
                answer.errorMessages.push('Last Year is required');
                matchedAnswer.errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'Last Year is required',
                });
            }
            isValid = isValid && !!toCheck;

            toCheck =
                answer.value &&
                typeof answer.value !== 'string' &&
                answer.value.ytd &&
                answer.value.ytd.length;
            if (!toCheck) {
                answer.errorMessages.push('YTD is required');
                matchedAnswer.errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'YTD is required',
                });
            }
            isValid = isValid && !!toCheck;
        } else {
            toCheck = (answer.value + '').length > 0;
            if (!toCheck) {
                answer.errorMessages.push('This field is required');
                matchedAnswer.errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'This field is required',
                });
            }
            isValid = isValid && !!toCheck;
        }
    }

    if (
        question.expiryRequirement === 2 &&
        typeof answer.expiresAt === 'string'
    ) {
        const answerDate = moment(new Date(answer.expiresAt));
        toCheck =
            answer.expiresAt &&
            answerDate.diff(moment(new Date()), 'days') >= 0;
        if (!toCheck) {
            answer.errorMessages.push('File expiry date required');
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: 'File expiry date required',
            });
        }
        isValid = isValid && !!toCheck;
    }

    if (question.numberRequirement === 2 && typeof answer.value === 'string') {
        toCheck = answer.value.length > 0;
        if (!toCheck) {
            answer.errorMessages.push('File number required');
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: 'File number required',
            });
        }
        isValid = isValid && toCheck;
    }

    if (question.maxValue) {
        toCheck =
            typeof answer.value === 'string' &&
            (isNaN(parseFloat(answer.value)) || //ignore empty answers
                (!isNaN(parseFloat(answer.value)) &&
                    parseFloat(answer.value) <= parseFloat(question.maxValue)));
        if (!toCheck) {
            answer.errorMessages.push(
                `Exceeds the maximum value ${question.maxValue}`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Exceeds the maximum value ${question.maxValue}`,
            });
        }
        isValid = isValid && toCheck;
    }

    if (question.minValue !== undefined) {
        toCheck =
            typeof answer.value === 'string' &&
            (isNaN(parseFloat(answer.value)) || //ignore empty answers
                (!isNaN(parseFloat(answer.value)) &&
                    parseFloat(answer.value) >= parseFloat(question.minValue)));
        if (!toCheck) {
            answer.errorMessages.push(
                `Under the minimum value ${question.minValue}`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Under the minimum value ${question.minValue}`,
            });
        }
        isValid = isValid && toCheck;
    }

    // note: datepicker already has validation, so these date errors should not occur
    let answerDate: any = 0;
    if (question.minDateOffset || question.maxDateOffset) {
        answerDate = moment(answer.value as string);
        if (answer.value && !answerDate.isValid()) {
            answer.errorMessages.push(`Date entered is invalid`);
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: 'Date entered is invalid',
            });
        }
    }

    if (
        question.maxDateOffset &&
        typeof answer.value === 'string' &&
        answer.value.length
    ) {
        toCheck = answerDate < moment().add(question.maxDateOffset, 'd');
        if (!toCheck) {
            answer.errorMessages.push(
                `Exceeds the maximum ${question.maxDateOffset} days offset`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Exceeds the maximum ${question.maxDateOffset} days offset`,
            });
        }
        isValid = isValid && toCheck;
    }

    if (
        question.minDateOffset &&
        typeof answer.value === 'string' &&
        answer.value.length
    ) {
        toCheck = answerDate > moment().add(question.minDateOffset, 'd');
        if (!toCheck) {
            answer.errorMessages.push(
                `Under the minimum ${question.minDateOffset} days offset`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Under the minimum ${question.minDateOffset} days offset`,
            });
        }
        isValid = isValid && toCheck;
    }

    if (isInteger(question.minLength) && typeof answer.value === 'string') {
        toCheck = question.minLength <= answer.value.length;
        if (!toCheck) {
            answer.errorMessages.push(
                `Word count must be at least ${question.minLength} characters long`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Word count must be at least ${question.minLength} characters long`,
            });
        }
        isValid = isValid && toCheck;
    }

    if (isInteger(question.maxLength) && typeof answer.value === 'string') {
        toCheck = question.maxLength >= answer.value.length;
        if (!toCheck) {
            answer.errorMessages.push(
                `Word count must not exceed ${question.maxLength} characters in length`
            );
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: `Word count must not exceed ${question.maxLength} characters in length`,
            });
        }
        isValid = isValid && toCheck;
    }

    // also check answer is different from rejected old answer
    /**
     *  TODO: Disabled for now until we get a better solution
     *
    if (answer.review && answer.review.isApproved === false) {
        const oldAnswer = answersCollection.answers.find(
            (oldAnswer) =>
                oldAnswer._links.self.href === answer._links.self.href
        ) as AnswerData;
        if (question.type === 'file') {
            toCheck = !isEqual(answer.files, oldAnswer.files);
        } else if (question.type === 'trend') {
            toCheck =
                typeof answer.value !== 'string' &&
                typeof oldAnswer.value !== 'string' &&
                (answer.value.yearBeforeLast !==
                    oldAnswer.value.yearBeforeLast ||
                    answer.value.lastYear !== oldAnswer.value.lastYear ||
                    answer.value.ytd !== oldAnswer.value.ytd);
        } else if (question.type === 'text') {
            toCheck =
                answer.value !== oldAnswer.value ||
                !isEqual(answer.files, oldAnswer.files);
        } else {
            toCheck = answer.value !== oldAnswer.value;
        }

        if (!toCheck) {
            answer.errorMessages.push('Please amend your answer');
            matchedAnswer.errors.push({
                type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                message: 'Please amend your answer',
            });
        }
        isValid = isValid && !!toCheck;
    }
     */

    return !isValid;
};

export const updateError = (
    question: QuestionData,
    answer: AnswerData
): void => {
    const { errors }: AssessmentStateData = store.getState().assessments;
    const matchedAnswer = errors.find(
        (errorCollection) => errorCollection.question.id === question.id
    ) as AssessmentErrorCollection;
    if (!matchedAnswer || !matchedAnswer?.errors?.length) {
        return;
    }
    matchedAnswer.errors = [];
    answer.errorMessages = [];
    matchedAnswer.answer = answer;
    calculateError(matchedAnswer);

    store.dispatch(
        setErrors(
            errors.map((errorCollection, index) =>
                index === errors.indexOf(matchedAnswer)
                    ? matchedAnswer
                    : errorCollection
            )
        )
    );
};

// remove any question children errors (conditional-questions)
export const clearChildrenError = (question: QuestionData): void => {
    const { errors }: AssessmentStateData = store.getState().assessments;

    store.dispatch(
        setErrors(
            errors.filter((errorCollection) => {
                return (
                    question.id !==
                    Response.getLinkAttribute(
                        errorCollection.question,
                        'conditional-question',
                        'id'
                    )
                );
            })
        )
    );
};

export const getReviewErrors = (): AssessmentErrorCollection[] => {
    const assessmentState: AssessmentStateData = store.getState().assessments;
    const {
        reviewsCollection,
        answersCollection,
        questionsCollection,
        topicCollection,
        reviewChanges,
    } = assessmentState;

    const reviewErrors = reviewChanges
        .filter((review: ReviewData) => {
            let answer = answersCollection.answers.find(
                (answer) =>
                    answer._links.self.href === review._links.answer.href
            ) as AnswerData;
            let question = questionsCollection.questions.find(
                (question) =>
                    question._links.question.href ===
                    answer._links.question.href
            ) as QuestionInstanceData;

            if (Response.getLink(question.question, 'conditional-question')) {
                let conditionalQuestionHref =
                    question.question._links['conditional-question'].href;

                let parentAssessmentQuestion =
                    questionsCollection.questions.find(
                        (parentAssessmentQuestion) =>
                            parentAssessmentQuestion._links.question.href ===
                            conditionalQuestionHref
                    ) as QuestionInstanceData;

                // if for whatever reason the parent question is no longer in the questions list, then skip
                if (typeof parentAssessmentQuestion === 'undefined') {
                    return false;
                }

                let parentAnswer = answersCollection.answers.find(
                    (parentAnswer) =>
                        parentAnswer._links.question.href ===
                        parentAssessmentQuestion._links.question.href
                ) as AnswerData;

                let parentAnswerIsTrue = parentAnswer.value.toString();

                // if conditional required answer is not met for question, then skip
                if (
                    question.question.conditionRequirement.toString() !==
                    parentAnswerIsTrue
                ) {
                    return false;
                }
            }

            const historicalReviews = reviewsCollection.reviews.filter(
                (historicalReview) => {
                    return (
                        historicalReview._links.answer.href ===
                        review._links.answer.href
                    );
                }
            );

            // do not require comment if previously given
            const previousIsFailWithComment =
                historicalReviews[0] !== undefined &&
                !historicalReviews[0].isApproved &&
                historicalReviews[0].comment !== '';

            return (
                (!review.isApproved &&
                    review.comment === '' &&
                    !previousIsFailWithComment) ||
                review.isApproved === null
            );
        })
        //get answer, question && topic
        .map((review: ReviewData) => {
            let answer = answersCollection.answers.find(
                (answer) =>
                    answer._links.self.href === review._links.answer.href
            ) as AnswerData;
            let question = questionsCollection.questions.find(
                (question) =>
                    question._links.question.href ===
                    answer._links.question.href
            ) as QuestionInstanceData;
            let topic = topicCollection.topics.find(
                (topic) => topic.id === question.topicId
            ) as TopicData;
            const errors = [];

            if (review.isApproved === null) {
                answer.errorMessages = ['You must select Pending or Pass'];
                errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'You must select Pending or Pass',
                } as AssessmentFormError);
            } else {
                answer.errorMessages = ['Comment required for Pending answers'];
                errors.push({
                    type: ASSESSMENT_FORM_ERROR_TYPE_VALIDATION,
                    message: 'Comment required for Pending answers',
                } as AssessmentFormError);
            }

            return {
                question: question.question,
                topic,
                answer: answer,
                errors: errors,
            };
        });

    return reviewErrors;
};

/**
 * Returns true of a question is a valid conditional question. This means
 * if the parent conditional-question is empty or wrong it returns false.
 *
 * @param questionInstance question instance data
 * @returns {boolean} if the given question is a valid conditional question
 */
export const isValidConditionalQuestion = (
    questionInstance: QuestionInstanceData,
    topic: SortedTopic
): boolean => {
    const question = questionInstance.question;

    if (Response.getLink(question, 'conditional-question')) {
        let id = question._links['conditional-question'].id;

        const conditionalQuestion = topic.sortedQuestions.find(
            (instance: QuestionInstanceData) => instance.question.id === id
        ) as QuestionInstanceData;

        if (typeof conditionalQuestion === 'undefined') {
            return false;
        }

        const conditionalAnswer = getAnswerOrNew(conditionalQuestion.question);

        if (typeof conditionalAnswer === 'undefined') {
            return false;
        }

        const requirementMatchesAnswer =
            question.conditionRequirement.toString() !==
            conditionalAnswer.value.toString();

        if (requirementMatchesAnswer) {
            return false;
        }
    }

    return true;
};
