const routes = {
    client_details_sp_requirements: {
        href: '/clients/list/:clientId/sp-requirements',
        text: 'SP Requirements',
    },
    client_details_sp_requirements_new: {
        href: '/clients/list/:clientId/sp-requirements/new',
        text: 'Add',
    },
};

export default routes;
