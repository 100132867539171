import { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import ProtectedRoute from 'components/elements/ProtectedRoute';
import ActiveSupplyChainReport from 'components/elements/client/report/activeSupplyChainReport';
import ReportList from 'components/elements/client/reportList';

class Reports extends Component {
    render() {
        return (
            <div className="Reports">
                <Switch>
                    <ProtectedRoute
                        path="/reports/list"
                        component={ReportList}
                        exact
                    />
                    <ProtectedRoute
                        path="/reports/list/active-supply-chain-report"
                        component={ActiveSupplyChainReport}
                        exact
                    />
                    <Redirect to="/reports/list?order=name" />
                </Switch>
            </div>
        );
    }
}

export default Reports;
