import { H1, H4 } from '@blueprintjs/core';
import { Table } from 'components/elements';
import { Loading, NoResultsFound } from 'components/elements/wrappers';
import { CAFMServiceProviderData } from 'interface';
import {
    ELBServiceProviderListData,
    ElogbooksServiceProviderFilter,
} from 'interface/Integrations';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadClientELBServiceProviders } from 'service/Integration/IntegrationService';

interface Props {
    clientId: string;
}

const ServiceProvidersBelowRequirementsTable = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<ELBServiceProviderListData>(
        {} as ELBServiceProviderListData
    );

    useEffect(() => {
        if (props.clientId) {
            loadServiceProviders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clientId]);

    const loadServiceProviders = async () => {
        setIsLoading(true);

        const response = await loadClientELBServiceProviders(props.clientId, {
            order: '-belowRequirements',
            limit: 10,
        } as ElogbooksServiceProviderFilter);

        setResponse(response);

        setIsLoading(false);
    };

    const columns = [
        {
            name: 'Service Provider',
            property: 'elogbooksName',
            grow: 3,
        },
        {
            name: 'Jobs Completed',
            grow: 3,
            type: 'callback',
            callback: (row: CAFMServiceProviderData) => {
                const spURL = encodeURI(
                    `/supply-chain/elogs-cafm-stats/list?limit=10&statusAtCompletion=2&order=-completedAt&page=1&serviceProvider=${row.elogbooksID}&serviceProvider-object={"id":${row.elogbooksID},"name":"${row.elogbooksName}"}&type=reactive`
                );
                return <Link to={spURL}>{row.belowRequirementCount}</Link>;
            },
        },
    ];

    return (
        <div>
            <H1>Service Provider Overview - Below Requirements</H1>
            <H4>
                (Showing all Jobs completed Below Requirements per Service
                Provider for the last 30 days)
            </H4>
            <Loading isLoading={isLoading}>
                <NoResultsFound count={response?.serviceProviders?.length}>
                    <Table
                        data={response?.serviceProviders}
                        columns={columns}
                    />
                </NoResultsFound>
            </Loading>
        </div>
    );
};

export default ServiceProvidersBelowRequirementsTable;
