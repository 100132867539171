const ORGANISATION_TYPE_CONSTRUCTION = "Construction";
const ORGANISATION_TYPE_EDUCATION = "Education";
const ORGANISATION_TYPE_GOVERNMENT = "Government";
const ORGANISATION_TYPE_HEALTHCARE = "Health Care";
const ORGANISATION_TYPE_LEISURE_ENTERTAINMENT_FOOD_BEVERAGE = "Leisure, Entertaintment, Food & Beverage";
const ORGANISATION_TYPE_MANUFACTURING = "Manufacturing";
const ORGANISATION_TYPE_NOT_FOR_PROFIT = "Not for Profit";
const ORGANISATION_TYPE_PROPERTY_INVESTMENT = "Property Investment";
const ORGANISATION_TYPE_PROPERTY_MANAGEMENT = "Property Management";
const ORGANISATION_TYPE_RETAIL = "Retail";
const ORGANISATION_TYPE_UTILITIES_ENERGY = "Utilities/Energy";
const ORGANISATION_TYPE_OTHER = "Other";

const ORGANISATION_TYPE_CONSTRUCTION_OPTION =        {label: ORGANISATION_TYPE_CONSTRUCTION,        value: ORGANISATION_TYPE_CONSTRUCTION};
const ORGANISATION_TYPE_EDUCATION_OPTION =           {label: ORGANISATION_TYPE_EDUCATION,           value: ORGANISATION_TYPE_EDUCATION};
const ORGANISATION_TYPE_GOVERNMENT_OPTION =          {label: ORGANISATION_TYPE_GOVERNMENT,          value: ORGANISATION_TYPE_GOVERNMENT};
const ORGANISATION_TYPE_HEALTHCARE_OPTION =          {label: ORGANISATION_TYPE_HEALTHCARE,          value: ORGANISATION_TYPE_HEALTHCARE};
const ORGANISATION_TYPE_LEISURE_ENTERTAINMENT_FOOD_BEVERAGE_OPTION = {label: ORGANISATION_TYPE_LEISURE_ENTERTAINMENT_FOOD_BEVERAGE, value: ORGANISATION_TYPE_LEISURE_ENTERTAINMENT_FOOD_BEVERAGE};
const ORGANISATION_TYPE_MANUFACTURING_OPTION =       {label: ORGANISATION_TYPE_MANUFACTURING,       value: ORGANISATION_TYPE_MANUFACTURING};
const ORGANISATION_TYPE_NOT_FOR_PROFIT_OPTION =      {label: ORGANISATION_TYPE_NOT_FOR_PROFIT,      value: ORGANISATION_TYPE_NOT_FOR_PROFIT};
const ORGANISATION_TYPE_PROPERTY_INVESTMENT_OPTION = {label: ORGANISATION_TYPE_PROPERTY_INVESTMENT, value: ORGANISATION_TYPE_PROPERTY_INVESTMENT};
const ORGANISATION_TYPE_PROPERTY_MANAGEMENT_OPTION = {label: ORGANISATION_TYPE_PROPERTY_MANAGEMENT, value: ORGANISATION_TYPE_PROPERTY_MANAGEMENT};
const ORGANISATION_TYPE_RETAIL_OPTION =              {label: ORGANISATION_TYPE_RETAIL,              value: ORGANISATION_TYPE_RETAIL};
const ORGANISATION_TYPE_UTILITIES_ENERGY_OPTION =    {label: ORGANISATION_TYPE_UTILITIES_ENERGY,    value: ORGANISATION_TYPE_UTILITIES_ENERGY};
const ORGANISATION_TYPE_OTHER_OPTION =               {label: ORGANISATION_TYPE_OTHER,               value: ORGANISATION_TYPE_OTHER};

const ORGANISATION_TYPE_OPTIONS = [
    ORGANISATION_TYPE_CONSTRUCTION_OPTION,
    ORGANISATION_TYPE_EDUCATION_OPTION,
    ORGANISATION_TYPE_GOVERNMENT_OPTION,
    ORGANISATION_TYPE_HEALTHCARE_OPTION,
    ORGANISATION_TYPE_LEISURE_ENTERTAINMENT_FOOD_BEVERAGE_OPTION,
    ORGANISATION_TYPE_MANUFACTURING_OPTION,
    ORGANISATION_TYPE_NOT_FOR_PROFIT_OPTION,
    ORGANISATION_TYPE_PROPERTY_INVESTMENT_OPTION,
    ORGANISATION_TYPE_PROPERTY_MANAGEMENT_OPTION,
    ORGANISATION_TYPE_RETAIL_OPTION,
    ORGANISATION_TYPE_UTILITIES_ENERGY_OPTION,
    ORGANISATION_TYPE_OTHER_OPTION
];

const SUBSCRIPTION_LEVELS = [
    { label: 'Legacy', value: 'Legacy' },
    { label: 'Bronze', value: 'Bronze' },
    { label: 'Silver', value: 'Silver' },
    { label: 'Gold', value: 'Gold' }
];

const SUBSCRIPTION_STATUSES = {
    ACTIVE: 'Active',
    EXPIRED: 'Expired',
    PENDING: 'Upcoming',
};

export {
    ORGANISATION_TYPE_OPTIONS,
    ORGANISATION_TYPE_PROPERTY_MANAGEMENT,
    SUBSCRIPTION_LEVELS,
    SUBSCRIPTION_STATUSES
};

