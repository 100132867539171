import { Menu, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { InputElementIcon } from 'components/elements';
import { NoResultsFound } from 'components/elements/wrappers';
import Key from 'constants/keyCodes';
import { globalDebounce } from "helpers/helpers";
import { get } from 'lodash';
import { Component } from 'react';
import { HTTP, Utility } from 'service';

class SuggestRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: props.defaultItem || null,
            searchItems: [],
        };
    }

    componentDidMount() {
        if (this.props.searchUrl) {
            this.updateList('');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultItem !== this.props.defaultItem) {
            this.setState({
                selectedItem: this.props.defaultItem,
                searchItems: [],
            });
            this.updateList(this.props.defaultItem);
        }
    }

    getDisplayValueFromItem = (item) => {
        const { valueProperty = '' } = this.props;

        if (valueProperty.indexOf(',') !== -1) {
            let predicates = valueProperty.split(',');
            let haystack = [];

            predicates.forEach((predicate) => {
                let matchedValue = get(item, predicate);

                if (matchedValue) {
                    haystack.push(matchedValue);
                }
            });

            return haystack.join(' ');
        }

        return get(item, valueProperty || 'name');
    };

    updateList = async (query) => {
        const {
            searchUrl,
            searchParams = {},
            searchObjectName,
            searchKey = 'name'
        } = this.props;

        if (!searchUrl || !searchObjectName) {
            return console.warn(
                'Missing required params searchUrl or searchObjectName'
            );
        }

        const searchItems = await HTTP
            .get(
                searchUrl,
                {
                    [searchKey]: query,
                    ...searchParams
                }
            )
            .then((response) => (
                response 
                    ? response.data[searchObjectName]
                    : response
            ));

        if (!searchItems) {
            return this.setState({ searchItems: [] });
        }

        this.setState({ searchItems });
    };

    handleOnKeyUp = (event) => {
        const { key, target } = event;
        const { searchUrl, onKeyUp } = this.props;

        const shouldDoSearch = (
            searchUrl &&
            !Key.isArrowKey(key) &&
            key !== "Enter"
        );

        if (shouldDoSearch) {
            const search = target.value;

            globalDebounce(() => {
                this.updateList(search);
            }, 'updateList', 250);
        }

        if (typeof onKeyUp === 'function') {
            onKeyUp(event);
        }
    }

    handleOnKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
        }

        return true;
    }

    render() {
        const {
            id,
            fill,
            resetOnSelect,
            resetOnClose,
            resetOnQuery,
            closeOnSelect,
            popoverProps,
            searchUrl,
            items,
            itemListPredicate,
            inputValueRenderer,
            itemRenderer,
            valueProperty,
            idProperty,
            isRequired,
            onItemSelect,
            onKeyUp,
            defaultItem,
            showClearButton,
            placeholder,
            leftElement,
            ...rest
        } = this.props;

        const inputProps = {
            placeholder: placeholder,
            required: isRequired,
            leftElement: leftElement ?? void 0,
            rightElement: showClearButton === false ? null :
                InputElementIcon('Clear', 'times', (event) => {
                this.setState({ selectedItem: null });
                this.props.onItemSelect(null, event);

                if (searchUrl) {
                    this.updateList('');
                }
            }),
        };

        const { selectedItem, searchItems } = this.state;
        const defaultPopoverProps = { minimal: true, usePortal: false, }

        return (
            <Suggest id={id}
                popoverProps={ popoverProps ?? defaultPopoverProps }
                noResults={<MenuItem disabled={true}><NoResultsFound /></MenuItem>}
                fill={fill ?? true}
                resetOnSelect={resetOnSelect ?? true}
                closeOnSelect={closeOnSelect ?? true}
                resetOnClose={resetOnClose ?? true}
                resetOnQuery={resetOnQuery ?? true}
                selectedItem={selectedItem}
                inputValueRenderer={ typeof inputValueRenderer === 'function' ? inputValueRenderer : (item) => {
                    return this.getDisplayValueFromItem(item);
                } }
                inputProps={ inputProps }
                onKeyUp={this.handleOnKeyUp}
                onKeyDown={this.handleOnKeyDown}
                itemListPredicate={ (query, items) => {
                    if (itemListPredicate) {
                        return itemListPredicate(query, items);
                    }

                    query = query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
                    const filteredItems = [];

                    items.forEach((item, index) => {
                        let value = this.getDisplayValueFromItem(item);

                        if (value.match(new RegExp(query, 'i')) !== null) {
                            filteredItems.push(item);
                        }
                    });

                    return filteredItems;
                }}
                onItemSelect={(item, event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    this.props.onItemSelect(item, event);
                    this.setState({ selectedItem: item });
                }}
                itemListRenderer={(itemListProps) => {
                    const items = itemListProps.items.map(
                        itemListProps.renderItem
                    );

                    return <Menu>{items}</Menu>;
                }}
                itemRenderer={
                    typeof itemRenderer === 'function'
                        ? itemRenderer
                        : (item, options) => {
                              if (!options.modifiers.matchesPredicate) {
                                  return null;
                              }

                              const id = get(item, idProperty || 'id');
                              let value = this.getDisplayValueFromItem(item);

                              return (
                                  <MenuItem
                                      active={options.modifiers.active}
                                      disabled={options.modifiers.disabled}
                                      key={id}
                                      onClick={options.handleClick}
                                      text={Utility.highlightText(
                                          value,
                                          options.query
                                      )}
                                  />
                              );
                          }
                }
                items={searchUrl ? searchItems : items}
                {...rest}
            />
        );
    }
}

export default SuggestRenderer;
