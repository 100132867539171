import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';

import {
    AssessmentTypeInstanceData,
    AssessmentTypeInstanceListFilter,
    ListCollection,
    UserData,
} from 'interface';
import { HTTP, Response } from 'service';

class InstanceService {
    static takeOwnership = async (
        instance: AssessmentTypeInstanceData,
        type: 'assessor' | 'reviewer',
        callback: Function | undefined
    ) => {
        const link = Response.getLink(
            instance,
            type === 'assessor'
                ? 'take-assessor-ownership'
                : 'take-reviewer-ownership'
        );
        const result = await HTTP.action(
            'patch',
            link,
            void 0,
            void 0,
            'Failed to take ownership of assessment'
        );

        result &&
            result.status === 204 &&
            typeof callback === 'function' &&
            callback();
    };

    static resendReceipt = async (sendUrl: string, email: string) => {
        const result = await HTTP.action(
            'post',
            sendUrl,
            {
                email: email,
            },
            void 0,
            'Failed to resend receipt'
        );

        return result && result.status === 200;
    };

    static adminChangeOwnership = async (
        instance: AssessmentTypeInstanceData,
        user: UserData,
        callback: Function | undefined
    ) => {
        const link = Response.getLink(instance, 'change-assessor-ownership');
        const result = await HTTP.action(
            'patch',
            link,
            {
                _links: {
                    user: user._links?.self,
                },
            },
            void 0,
            'Failed to update ownership of assessment'
        );

        result &&
            result.status === 204 &&
            typeof callback === 'function' &&
            callback();

        return result && result.status === 204;
    };

    static getComments = async (instance: AssessmentTypeInstanceData) => {
        if (Response.getLink(instance, 'comments')) {
            let response = await HTTP.get(
                Response.getLink(instance, 'comments')
            );
            if (response) {
                return response.data.comments;
            }
        }
        return [];
    };

    static loadList = async (
        props: RouteComponentProps<any>,
        filter: AssessmentTypeInstanceListFilter
    ): Promise<{
        collection: ListCollection<AssessmentTypeInstanceData>;
        filter: AssessmentTypeInstanceListFilter;
    }> => {
        const { history, location } = props;

        // custom SP name filtering
        if (
            filter.order &&
            filter.order.includes('_links.company.registeredCompanyName')
        ) {
            let ordering = '-lastStatusUpdate';

            if (location.pathname.includes('completed')) {
                ordering = '-completedAt';
            } else if (location.pathname.includes('purchased')) {
                ordering = '-purchasedAt';
            } else if (location.pathname.includes('refunded')) {
                ordering = '-refundedAt';
            } else if (location.pathname.includes('expiring')) {
                ordering = 'expiryDate';
            } else if (location.pathname.includes('assessment-comments')) {
                ordering = '-completedAt';
            }

            filter.order = filter.order.includes('-')
                ? `-registeredCompanyName,${ordering}`
                : `registeredCompanyName,${ordering}`;
        }

        let url = '/assessment-type-instances';
        if (location.pathname.includes('assessment-comments')) {
            url = '/assessment-types/instance/comments';
        }

        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            filter ?? {},
            {},
            'Unable to load assessment instance list'
        );

        if (response && response.data) {
            // custom SP name filtering revert
            if (
                filter.order &&
                filter.order.includes('registeredCompanyName')
            ) {
                filter.order = filter.order.includes('-registeredCompanyName')
                    ? '-_links.company.registeredCompanyName'
                    : '_links.company.registeredCompanyName';
            }

            //Location.setQueryStringFromObject(history, location, filter);

            let queryStringFilterParams = queryString.stringify(filter);
            if (
                location.search &&
                '?' + queryStringFilterParams !== location.search
            ) {
                history.push({ search: queryStringFilterParams });
            }

            return {
                collection: {
                    data:
                        response.data.assessmentTypeInstances ??
                        response.data.comments,
                    count: response.data.count,
                    limit: response.data.limit,
                    page: response.data.page,
                } as ListCollection<AssessmentTypeInstanceData>,
                filter: filter,
            };
        } else {
            return {
                collection: {} as ListCollection<AssessmentTypeInstanceData>,
                filter: filter,
            };
        }
    };

    static downloadList = async (
        filter: AssessmentTypeInstanceListFilter
    ): Promise<void> => {
        await HTTP.stream(
            '/assessment-type-instances/download',
            {},
            filter ? filter : {}
        );

        return;
    };
}

export default InstanceService;
