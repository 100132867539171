import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { H1 } from '@blueprintjs/core';
import { ButtonLink, Icon, ItemCount, Paginator, Reload, Table } from 'components/elements';
import { Filter, Loading, NoResultsFound } from 'components/elements/wrappers';
import { Filters, HTTP, Location, Response } from 'service';
import { ACTIVE_OPTIONS_ACTIVE, ACTIVE_OPTIONS_WITH_NONE_SELECTED } from 'constants/active';
import queryString from 'query-string';

const defaultFilterDataProp = {
    isActive: {
        value: ACTIVE_OPTIONS_ACTIVE.value,
        data: ACTIVE_OPTIONS_ACTIVE.value,
    },
};

class QuestionList extends Component
{
    columns = [
        { name: 'Summary', property: 'summary', sortable: true, grow: 3, },
        { name: 'Category', property: '_links.category.name', sortable: true, grow: 3 },
        { name: 'Guidance Notes', property: 'guidanceNotes', sortable: true, grow: 8 },
        { name: 'Status', property: 'isActive', type: 'mapping', mapping: ACTIVE_OPTIONS_WITH_NONE_SELECTED, sortable: true, grow: 2 },
        { name: 'Type', property: 'type', grow: 1 },
        { name: '', property: '', type: 'nav-button', grow: 1, route: this.getRoute, buttonIntent: 'primary', buttonText: 'View', right: true, },
    ];

    defaultFilters = {
        order: 'summary',
    };

    static defaultProps = {
        filterData: defaultFilterDataProp,
    };

    constructor(props)
    {
        super(props);

        this.state = {
            collectionResponse: {},
            filter: queryString.parse(props.location.search),
            filterData: Filters.getFilterStateFromUrl(
                this.props.location.search.slice(1),
                props.filterData
            ),
        };

        this.load = this.load.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    componentDidMount()
    {
        this.load();
    }

    getRoute(row, column)
    {
        return '/admin/questions/' + row.id;
    }

    render()
    {
        const collection = this.state.collectionResponse || {};
        const { match } = this.props;
        const { filterData } = this.state;

        return (
               <div className="QuestionList">
                   <H1 className="clearfix">
                       Question List

                       <ItemCount count={ collection.count } />

                       <Reload load={ this.load } />

                       {
                           Response.getLink(collection, 'create') === null ?
                               null :
                               (
                                   <ButtonLink type="button"
                                       to={`${match.url}/add`}
                                       intent="primary"
                                       className="float-right">
                                       <Icon icon="plus-circle" />
                                       Add new Question
                                   </ButtonLink>
                               )
                       }
                   </H1>

                    <Filter onClear={ this.clearFilters }
                        onFilter={ (filters, filterData) => this.onFilter(filters, filterData) }
                        filterData={ filterData }>
                        <Filter.Text label="Summary" filterKey="summary" />
                        <Filter.SingleSelect label="Status" filterKey="isActive" options={ ACTIVE_OPTIONS_WITH_NONE_SELECTED } />

                    </Filter>

                    <Loading isLoading={ this.state.isLoading } >
                        <NoResultsFound count={ collection.count }>
                            <Table data={ collection.questions }
                                columns={ this.columns }
                                onSort={ this.load }
                                ordering={ this.state.filterData.order } />

                        <Paginator page={ collection.page }
                            count={ collection.count }
                            limit={ collection.limit }
                            onPage={ (page) => this.changePage(page) } />
                        </NoResultsFound>
                    </Loading>
               </div>
        );
    }

    clearFilters()
    {
        this.setState({filterData: {}});
        this.onFilter({}, {});
    }

    onFilter(filters, filterData)
    {
        this.load(Object.assign({}, this.defaultFilters, filterData, { page: 1 }), true);
    }

    changePage(page) {
        this.load(Object.assign(this.state.filterData, { page: page.page }), true);
    }

    load(params = {}, paramsOverride = false)
    {
        if (this.state.isLoading) {
            return;
        }

        const filterParams = paramsOverride ? params : Object.assign({}, this.defaultFilters, this.props.filter, this.state.filterData, params);
        Location.setQueryStringFromObject(this.props.history, this.props.location, filterParams);

        this.setState({isLoading: true, filterData: filterParams});

        HTTP
            .get('/questions', Filters.convertFilterDataToRequestData(filterParams))
            .then((response) => {
                if (response) {
                    this.setState({isLoading: false, collectionResponse: response.data});

                    return true;
                }

                toast.error('Unable to fetch a list of Questions');
                this.setState({isLoading: false});

                return false;
            });
    }
}

export default QuestionList;
