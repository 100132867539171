import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { NONE_SELECTED_OPTION } from 'constants/none-selected';
import { parseDateString } from 'helpers/helpers';
import {
    ClientData,
    ClientListData,
    Company,
    CompanyReportFilter,
    DonutChartData,
    ListCollection,
    RedFlagReducerState,
    SupplyChainReducerState,
    SupplyChainRequirementsCount,
    SupplyChainSPCount,
    SupplyChainSPCountReducerState,
    SupplyChainSPTotalsReducerState,
    SupplyChainStatusCountData,
    SupplyChainStatusReducerState,
    SupplyChainSuspendedStatusReducerState,
    SupplyChainTrendData,
    SupplyChainTrendReducerState,
    UserData,
} from 'interface';
import { ServiceProviderCategory } from 'interface/Client/ServiceProviderCategory';
import { SupplyChain } from 'interface/SupplyChain';
import { HTTP, Response } from 'service';

class ClientService {
    static loadClientList: (
        filterParams?: {}
    ) => Promise<ClientListData | null> = async (filterParams = {}) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients`,
            { ...filterParams, limit: 500, datagroup: 'search', order: 'name' },
            {},
            'Unable to load client'
        );
        if (response) {
            return response.data as ClientListData;
        } else {
            return null;
        }
    };

    static loadClient: (clientId: string) => Promise<ClientData> = async (
        clientId: string
    ) => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/${clientId}`,
            { datagroup: 'details' },
            {},
            'Unable to load client'
        );
        if (response) {
            return response.data as ClientData;
        } else {
            return {} as ClientData;
        }
    };

    static getSupplyChainRequirementsState = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<SupplyChainReducerState>> => {
        let resource = Response.getLink(
            client,
            'supply-chain-requirement-counts'
        );

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const getCount = await this.loadSupplyChainRequirementsCount(resource);

        return {
            isVisible: true,
            isLoading: !getCount,
            counts: this.processSupplyChainRequirementsCounts(getCount),
            satisfiedFraction: this.supplyChainSatisfiedFraction(getCount),
        };
    };

    static putSupplyChain = async (
        supplyChainId: string,
        data: object,
        callback: (response: AxiosResponse | undefined) => void
    ): Promise<SupplyChain> => {
        const response = await HTTP.action(
            'put',
            `/supply-chain/${supplyChainId}`,
            data,
            {},
            'Could not update Supply Chain'
        );

        if (typeof callback === 'function') {
            callback(response);
        }

        return response ? response.data : void 0;
    };

    static getSupplyChainTrendState = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<SupplyChainTrendReducerState>> => {
        let resource = Response.getLink(client, 'supply-chain-trend');

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const data = await this.loadSupplyChainTrend(resource);

        const trends = data.clientCounts.map((clientCount) => {
            const date = parseDateString(clientCount.createdAt);

            return {
                x: date.startOf('month'),
                y: clientCount.meetsAllPercentage,
            };
        });

        return {
            isVisible: true,
            isLoading: false,
            trends,
        };
    };

    static getSupplyChainSPTotalsState = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<SupplyChainSPTotalsReducerState>> => {
        let resource = Response.getLink(client, 'supply-chain-trend');

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const data = await this.loadSupplyChainTrend(resource);

        const totals = data.clientCounts.map((clientCount) => {
            const date = parseDateString(clientCount.createdAt);

            return {
                x: date.startOf('month'),
                y: clientCount.total,
            };
        });

        return {
            isVisible: true,
            isLoading: false,
            totals,
        };
    };

    static getSupplyChainStatusState = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<SupplyChainStatusReducerState>> => {
        let resource = Response.getLink(client, 'supply-chain-status-counts');

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const data = await this.loadSupplyChainStatusCounts(resource);

        return {
            isVisible: true,
            isLoading: false,
            counts: [
                {
                    x: 'active',
                    y: data.active,
                },
                {
                    x: 'pending',
                    y: data.pending,
                },
            ],
        };
    };

    static getRedFlagState = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<RedFlagReducerState>> => {
        let resource = Response.getLink(
            client,
            'supply-chain-credit-health-check'
        );

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const getCount = await ClientService.loadRedFlagCount(resource);

        return {
            isLoading: !getCount,
            counts: ClientService.processRedFlagStatusCounts(getCount),
        };
    };

    static getSPCountData = async (
        client: ClientData,
        selectedCategory?: string
    ): Promise<Partial<SupplyChainSPCountReducerState>> => {
        let resource = Response.getLink(client, 'supply-chain-sp-counts');

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const getCounts = await ClientService.loadSPCountData(resource);

        return {
            isLoading: !getCounts,
            SSIPCounts: ClientService.processSSIPCounts(getCounts),
            prequalifiedCounts:
                ClientService.processPrequalifiedCounts(getCounts),
            SSIPFraction: ClientService.getSSIPFraction(getCounts),
            prequalifiedFraction:
                ClientService.getPrequalifiedFraction(getCounts),
        };
    };

    static getSPCategoriesByClientId = async (
        clientId: string,
        searchParams: {} = { order: 'name' }
    ): Promise<ServiceProviderCategory[]> => {
        const response = await HTTP.action(
            'get',
            `/clients/${clientId}/service-provider-categories`,
            { ...searchParams },
            {},
            'Unable to load Service Provider Categories'
        );

        if (response) {
            return response.data.categories as ServiceProviderCategory[];
        }
        return [];
    };

    static getSPCategories = async (
        client: ClientData,
        searchParams: {} = { order: 'name' }
    ): Promise<ServiceProviderCategory[]> => {
        const categoryLink = Response.getLink(client, 'sp-categories');
        const response = await HTTP.action(
            'get',
            categoryLink,
            { ...searchParams },
            {},
            'Unable to load Service Provider Categories'
        );

        if (response) {
            return response.data.categories as ServiceProviderCategory[];
        }
        return [];
    };

    static loadSupplyChainRequirementsCount = async (
        url: string
    ): Promise<SupplyChainRequirementsCount> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            {},
            {},
            'Unable to load supply chain requirements'
        );
        if (response) {
            return response.data as SupplyChainRequirementsCount;
        } else {
            return {
                below: 0,
                expiring: 0,
                meetsAll: 0,
            } as SupplyChainRequirementsCount;
        }
    };

    static loadSupplyChainStatusCounts = async (
        url: string
    ): Promise<SupplyChainStatusCountData> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            {},
            {},
            'Unable to load supply chain status counts'
        );
        if (response) {
            return response.data as SupplyChainStatusCountData;
        } else {
            return {
                pending: 0,
                active: 0,
            } as SupplyChainStatusCountData;
        }
    };

    static loadRedFlagCount = async (
        link: string | undefined = '/supply-chain/credit-health-check'
    ): Promise<DonutChartData[]> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            link,
            {},
            {},
            'Unable to load Red Flag Status'
        );
        if (response) {
            return response.data as DonutChartData[];
        } else {
            return [] as DonutChartData[];
        }
    };

    static loadSPCountData = async (
        link: string | undefined
    ): Promise<SupplyChainSPCount> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            link,
            {},
            {},
            'Unable to load supply chain Service Provider counts'
        );
        if (response) {
            return response.data as SupplyChainSPCount;
        }

        return {
            isSSIPAccreditation: 0,
            isSSIPDTS: 0,
            notSSIP: 0,
            isProsurePreQualified: 0,
            notPreQualified: 0,
        } as SupplyChainSPCount;
    };

    static loadSupplyChainTrend = async (
        url: string
    ): Promise<SupplyChainTrendData> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            url,
            {},
            {},
            'Unable to load supply chain requirements'
        );
        if (response?.data) {
            return response.data as SupplyChainTrendData;
        } else {
            return {
                count: 0,
            } as SupplyChainTrendData;
        }
    };

    static processSupplyChainRequirementsCounts = (
        count: SupplyChainRequirementsCount
    ): DonutChartData[] => {
        return [
            { name: 'meetsAll', label: 'Meets All', value: count.meetsAll },
            { name: 'expiring', label: 'Expiring', value: count.expiring },
            { name: 'below', label: 'Below', value: count.below },
        ] as DonutChartData[];
    };

    static supplyChainSatisfiedFraction = (
        count: SupplyChainRequirementsCount
    ): number => {
        let total = count.meetsAll + count.expiring + count.below;
        let satisfied = count.meetsAll + count.expiring;

        if (total === 0 || satisfied === 0) {
            return 0;
        }

        return Math.floor((satisfied / total) * 100);
    };

    static processRedFlagStatusCounts = (
        count: DonutChartData[]
    ): DonutChartData[] => {
        // filter out undesirebles line "dissolved"
        return (
            count.filter &&
            count
                .filter((row) => {
                    return [
                        'GOLD',
                        'SILVER',
                        'BRONZE',
                        'ONE_RED_FLAG',
                        'TWO_RED_FLAGS',
                        'THREE_RED_FLAGS',
                        'NEWLY_INCORPORATED',
                        'PRE_INSOLVENT',
                        'PROVISIONAL_SILVER',
                        'PROVISIONAL_BRONZE',
                        'PROVISIONAL_ONE_RED_FLAG',
                        'PROVISIONAL_TWO_RED_FLAGS',
                    ].includes(row.name);
                })
                .map((row) => {
                    switch (row.name) {
                        case 'GOLD':
                            row.label = 'Gold';
                            break;
                        case 'SILVER':
                            row.label = 'Silver';
                            break;
                        case 'BRONZE':
                            row.label = 'Bronze';
                            break;
                        case 'ONE_RED_FLAG':
                            row.label = '1 Red Flag';
                            break;
                        case 'TWO_RED_FLAGS':
                            row.label = '2 Red Flags';
                            break;
                        case 'THREE_RED_FLAGS':
                            row.label = '3 Red Flags';
                            break;
                        case 'NEWLY_INCORPORATED':
                            row.label = 'Newly Incorporated';
                            break;
                        case 'PRE_INSOLVENT':
                            row.label = 'Pre Insolvent';
                            break;
                        case 'PROVISIONAL_SILVER':
                            row.label = 'Prov. Silver';
                            break;
                        case 'PROVISIONAL_BRONZE':
                            row.label = 'Prov. Bronze';
                            break;
                        case 'PROVISIONAL_ONE_RED_FLAG':
                            row.label = 'Prov. 1 Red Flag';
                            break;
                        case 'PROVISIONAL_TWO_RED_FLAGS':
                            row.label = 'Prov. 2 Red Flags';
                            break;
                    }
                    return row;
                })
        );
    };

    static processSSIPCounts = (
        count: SupplyChainSPCount
    ): DonutChartData[] => {
        return [
            {
                name: 'ssipverified',
                label: 'Prosure SSIP Verified',
                value: count.isSSIPAccreditation,
            },
            {
                name: 'ssipdts',
                label: 'Prosure SSIP DTS',
                value: count.isSSIPDTS,
            },
            { name: 'neither', label: 'Neither', value: count.notSSIP },
        ] as DonutChartData[];
    };

    static processPrequalifiedCounts = (
        count: SupplyChainSPCount
    ): DonutChartData[] => {
        return [
            {
                name: 'prequalified',
                label: 'Pre-Qualified Active',
                value: count.isProsurePreQualified,
            },
            {
                name: 'none',
                label: 'No Assessment',
                value: count.notPreQualified,
            },
        ] as DonutChartData[];
    };

    static getSSIPFraction = (count: SupplyChainSPCount): number => {
        return Math.floor(
            ((count.isSSIPAccreditation + count.isSSIPDTS) /
                (count.isSSIPAccreditation + count.isSSIPDTS + count.notSSIP)) *
                100
        );
    };

    static getPrequalifiedFraction = (count: SupplyChainSPCount): number => {
        return Math.floor(
            (count.isProsurePreQualified /
                (count.isProsurePreQualified + count.notPreQualified)) *
                100
        );
    };

    static getSupplyChainSuspendedState = async (
        clientResponse: ClientData,
        selectedCategory?: string
    ): Promise<SupplyChainSuspendedStatusReducerState> => {
        let resource = Response.getLink(
            clientResponse,
            'supply-chain-suspension-counts'
        );

        if (
            typeof selectedCategory !== undefined &&
            selectedCategory !== 'all-categories'
        ) {
            resource += '?category=' + selectedCategory;
        }

        const totalsResponse = await HTTP.action('get', resource);
        return {
            isLoading: false,
            counts: [
                {
                    name: 'suspendedCount',
                    label: 'Suspended',
                    value: totalsResponse?.data.suspendedCount,
                },
                {
                    name: 'notSuspendedCount',
                    label: 'Not Suspended',
                    value: totalsResponse?.data.notSuspendedCount,
                },
            ],
        } as SupplyChainSuspendedStatusReducerState;
    };

    static getClientListForDropdown = async () => {
        const options = [NONE_SELECTED_OPTION];
        const clients: ClientListData | null = await this.loadClientList();

        if (clients) {
            clients.clients.forEach((type) => {
                options.push({
                    label: type.name as string,
                    value: type.id as string,
                });
            });
        }

        return options;
    };

    static updateNotifications = async (
        clientResponse: ClientData,
        notifications: any
    ): Promise<boolean> => {
        const {
            notificationInviteeDeclined,
            notificationWeeklySummary,
            notificationSPJoinedSupplyChain,
            notificationSPLeavingSupplyChain,
        } = notifications;

        notifications.notificationInviteeDeclined =
            this.getRoleArrayFromOptions(notificationInviteeDeclined);
        notifications.notificationWeeklySummary = this.getRoleArrayFromOptions(
            notificationWeeklySummary
        );
        notifications.notificationSPJoinedSupplyChain =
            this.getRoleArrayFromOptions(notificationSPJoinedSupplyChain);
        notifications.notificationSPLeavingSupplyChain =
            this.getRoleArrayFromOptions(notificationSPLeavingSupplyChain);

        const response = await HTTP.action(
            'put',
            Response.getLink(clientResponse, 'notification-settings'),
            notifications,
            {},
            'Could not update Notifications',
            HTTP.handleFormErrors
        );

        if (response) {
            toast.success('Notifications updated successfully');
            return true;
        } else {
            toast.error('Unable to add integrations');
            return false;
        }
    };

    static getRoleArrayFromOptions = (roles: Array<any>) => {
        if (!roles?.length) {
            return [];
        }

        return roles.map((role: any) => role.value);
    };

    static loadActiveSupplyChainReport = async (
        filters: CompanyReportFilter,
        clientId: string
    ): Promise<ListCollection<Company>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/${clientId}/active-supply-chain-report`,
            filters,
            {},
            'Unable to load active supply chains report'
        );

        let collection = {} as ListCollection<Company>;

        if (response) {
            const { companies, ...rest } = response.data;
            collection = {
                data: response.data.companies.map((row: any) => {
                    return {
                        rfaRating:
                            row._links['credit-health-check']?.rating ?? '',
                        rating: row._links.rating?.rating ?? '',
                        ...row,
                    };
                }),
                ...rest,
            } as ListCollection<Company>;
        }

        return collection;
    };

    static downloadActiveSupplyChainReport = async (
        filters: CompanyReportFilter,
        clientId: string
    ): Promise<void> => {
        await HTTP.stream(
            `/clients/${clientId}/active-supply-chain-report`,
            {},
            { ...filters, isDownload: true }
        );

        return;
    };

    static loadClientUsers = async (
        clientId: string,
        filters: any
    ): Promise<ListCollection<UserData>> => {
        const response: AxiosResponse | void = await HTTP.action(
            'get',
            `/clients/${clientId}/users`,
            filters,
            {},
            'Unable to load client users'
        );

        if (response) {
            return response.data;
        }

        return {} as ListCollection<UserData>
    };
}

export default ClientService;
