import {
    Button,
    FormGroup,
    H2,
    H3,
    InputGroup,
    TextArea,
} from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { Component } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import {
    ButtonLink,
    HTMLSelect,
    Icon,
    InputElementIcon,
} from 'components/elements';
import FileSingleUpload from 'components/elements/file-upload/FileSingleUpload';
import { Loading } from 'components/elements/wrappers';
import BioProgress from 'components/service-provider/bio-progess/BioProgress';
import { ACTIVE_OPTIONS } from 'constants/active';
import {
    FORM_COMPLETION_PROPERTIES,
    ORGANISATION_TYPES,
    ORGANISATION_TYPE_SOLE_TRADER,
    SHAPE_COMPANY_RESPONSE,
} from 'constants/company';
import { COUNTRY_CODE_OPTIONS } from 'constants/countries';
import {
    MAX_FILE_UPLOAD_SIZE_MB,
    OPTIONAL_YES_NO_OPTIONS,
    YES_NO_OPTIONS,
} from 'constants/general';
import { HTTP, Response, Routing, StateHandler } from 'service';

import './service-provider-add-edit.scss';

class ServiceProviderAddEdit extends Component {
    static propTypes = {
        companyResponse: PropTypes.shape(SHAPE_COMPANY_RESPONSE),
    };
    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            highlightProgessFields: false,
            companyId:
                props.companyResponse?.id ?? props.match.params.id ?? undefined,
            companyResponse: props.companyResponse,
            isLoading: false,
            form: Object.assign(
                {
                    registeredCompanyName: '',
                    tradingName: '',
                    companyBio: '',
                    companiesHouseRegistrationNumber: '',
                    phone: '',
                    cisNumber: '',
                    uniqueTaxReferenceNumber: '',
                    vatNumber: '',
                    webAddress: '',
                    isActive: 'false',
                    directEmployees: '1',
                    bonaFideSubContractors: '0',
                    labourOnly: '0',
                    organisationSize: '1',
                    organisationType: ORGANISATION_TYPE_SOLE_TRADER,
                    minWorkValue: null,
                    maxWorkValue: null,
                    is24hour: null,
                    isServiceBySiteVisit: 'true',
                    standardPaymentDays: null,

                    primaryContactForename: '',
                    primaryContactFirstName: '',
                    primaryContactSurname: '',
                    primaryContactEmail: '',
                    primaryContactPhoneNumber: '',

                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                    postCode: '',
                    country: 'GB',

                    _links: {},
                },
                props.companyResponse || {}
            ),
        };
    }

    componentDidMount() {
        if (this.state.companyId && !this.state.companyResponse) {
            this.load();
        }
    }

    getHighlightClass = (className = '', formProperty) => {
        const { highlightProgessFields } = this.state;
        let highlightClass = '';

        if (highlightProgessFields && !formProperty) {
            highlightClass = 'highlight-field';
        }

        return `${className} ${highlightClass}`;
    };

    load = () => {
        if (this.state.isLoading) {
            return;
        }

        this.setState({ isLoading: true });

        HTTP.get(`/companies/${this.state.companyId}`, {
            datagroup: 'details',
        }).then((response) => {
            if (response) {
                this.setState({
                    isLoading: false,
                    companyResponse: response.data,
                    form: Object.assign({}, this.state.form, response.data),
                });

                return true;
            }

            toast.error('Unable to fetch Service Provider details');
            this.setState({ isLoading: false });

            return false;
        });
    };

    hasEmployeeCountChanged(company, form) {
        const { labourOnly, directEmployees, bonaFideSubContractors } = company;

        return (
            labourOnly !== Number(form.labourOnly) ||
            directEmployees !== Number(form.directEmployees) ||
            bonaFideSubContractors !== Number(form.bonaFideSubContractors)
        );
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const { history, match } = this.props;
        const { companyResponse, form } = this.state;

        const hasEmployeeCountChanged = this.hasEmployeeCountChanged(
            companyResponse,
            form
        );

        if (hasEmployeeCountChanged) {
            form.employeeCountsLastUpdated = new Date();
        }

        if (typeof companyResponse === 'object') {
            return HTTP.put(
                Response.getLink(companyResponse, 'edit'),
                form
            ).then((response) => {
                if (response) {
                    toast.success('Service Provider updated');

                    if (this.props.mergeCompanyResponse) {
                        this.props.mergeCompanyResponse(form);
                    }

                    Routing.navigateUpOneLevel(history, match);
                    return true;
                }

                toast.error('Unable to update Service Provider');

                return false;
            });
        }

        HTTP.post('/companies/new', form).then((response) => {
            if (response) {
                toast.success('Service Provider created');

                Routing.navigateUpOneLevel(history, match, response.data.id);

                return true;
            }

            toast.error('Unable to create Service Provider');

            return false;
        });
    }

    updateField = (e) => {
        const formData = StateHandler.getStateObject(e, this.state.form);
        formData.organisationSize =
            (parseInt(formData.directEmployees) || 0) +
            (parseInt(formData.labourOnly) || 0);
        this.setState({
            form: formData,
        });
    };

    onUpdate = (fileJson) => {
        this.setState({ form: Object.assign({}, this.state.form, fileJson) });
    };

    getForm = () => {
        const { history, match } = this.props;
        const isEditing = typeof this.state.companyId !== 'undefined';
        const { form } = this.state;

        const {
            _links,
            companiesHouseRegistrationNumber,
            companyBio,
            is24hour,
            isServiceBySiteVisit,
            maxWorkValue,
            minWorkValue,
            directEmployees,
            bonaFideSubContractors,
            labourOnly,
            organisationSize,
            organisationType,
            phone,
            registeredCompanyName,
            standardPaymentDays,
            webAddress,
        } = form;

        const logoLabel = (
            <div className={this.getHighlightClass('', _links?.logo)}>
                <div className="bp3-label">
                    Company logo
                    {InputElementIcon(
                        `Maximum 250x250px. Max file size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
                        'info'
                    )}
                </div>
            </div>
        );

        return (
            <>
                <div className="page-header sp-add-edit-header">
                    <h1 className="page-title">
                        {`Edit ${registeredCompanyName}`}
                    </h1>
                    <div className="bio-progress-container">
                        <BioProgress
                            completedProperties={FORM_COMPLETION_PROPERTIES}
                            data={{
                                ...this.state.form,
                                logo: Response.getLink(form, 'logo'),
                            }}
                        />
                        <button
                            className="bio-progress-btn"
                            onClick={() =>
                                this.setState({ highlightProgessFields: true })
                            }
                        >
                            View Remaining Required Fields
                        </button>
                    </div>
                </div>

                <br />

                <form
                    className="AddEdit"
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                    <H2 className="clearfix">Company Information</H2>

                    <FormGroup
                        label="Registered Company Name"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill required',
                            registeredCompanyName
                        )}
                        labelFor="registeredCompanyName"
                    >
                        <InputGroup
                            id="registeredCompanyName"
                            placeholder="Registered Company Name"
                            minLength={1}
                            maxLength={75}
                            value={registeredCompanyName}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            required
                        />
                    </FormGroup>
                    <FormGroup
                        label="Trading Name"
                        inline={true}
                        className="form-fill"
                        labelFor="tradingName"
                    >
                        <InputGroup
                            id="tradingName"
                            placeholder="Trading Name"
                            minLength={1}
                            maxLength={75}
                            value={form.tradingName}
                            rightElement={InputElementIcon(
                                'Enter if different to Registered Company Name',
                                'info'
                            )}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Company Bio / Summary"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill required',
                            companyBio
                        )}
                        labelFor="companyBio"
                    >
                        <TextArea
                            id="companyBio"
                            fill={true}
                            placeholder="Company Bio / Summary"
                            value={companyBio}
                            required
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Companies House Registration Number"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            companiesHouseRegistrationNumber
                        )}
                        labelFor="companiesHouseRegistrationNumber"
                    >
                        <InputGroup
                            id="companiesHouseRegistrationNumber"
                            placeholder="Companies House Registration Number"
                            minLength={8}
                            maxLength={8}
                            value={companiesHouseRegistrationNumber}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Web Address"
                        labelFor="webAddress"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            webAddress
                        )}
                    >
                        <InputGroup
                            id="webAddress"
                            placeholder="Web Address"
                            value={webAddress}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            pattern="^(https?://)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})(/.*)*"
                        />
                    </FormGroup>
                    <FormGroup
                        label="Phone Number"
                        inline={true}
                        className={this.getHighlightClass('form-fill', phone)}
                        labelFor="phone"
                    >
                        <InputGroup
                            id="phone"
                            fill={true}
                            type="phone"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            rightElement={InputElementIcon(
                                'Enter the phone number',
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Direct Employees"
                        labelFor="directEmployees"
                        className={this.getHighlightClass(
                            'form-fill required',
                            directEmployees
                        )}
                        inline={true}
                    >
                        <InputGroup
                            id="directEmployees"
                            fill="true"
                            placeholder="Direct Employees *"
                            value={directEmployees}
                            type="number"
                            min="0"
                            onChange={this.updateField}
                            required
                            rightElement={InputElementIcon(
                                `
                                Direct employees are workers hired by a company and paid a salary or
                                hourly wages through the company's payroll.
                            `,
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Bona Fide Sub Contractors"
                        labelFor="bonaFideSubContractors"
                        className={this.getHighlightClass(
                            'form-fill required',
                            bonaFideSubContractors
                        )}
                        inline={true}
                    >
                        <InputGroup
                            id="bonaFideSubContractors"
                            fill="true"
                            placeholder="Bona Fide Sub Contractors"
                            value={bonaFideSubContractors}
                            type="number"
                            required
                            min="0"
                            onChange={this.updateField}
                            rightElement={InputElementIcon(
                                `
                                Contractors who work without direction, hold their own insurance
                                and provide their own materials and tools.
                            `,
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Labour Only"
                        labelFor="labourOnly"
                        className={this.getHighlightClass(
                            'form-fill required',
                            labourOnly
                        )}
                        inline={true}
                    >
                        <InputGroup
                            id="labourOnly"
                            fill="true"
                            placeholder="Labour Only *"
                            value={labourOnly}
                            type="number"
                            min="0"
                            onChange={this.updateField}
                            required
                            rightElement={InputElementIcon(
                                `
                                According to UK law a labour only subcontractor is considered an employee.
                                They are working under your supervision and using material, equipment,
                                and tools that you have provided.
                            `,
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Number of Effective Employees"
                        labelFor="organisationSize"
                        className={this.getHighlightClass(
                            'form-fill',
                            organisationSize
                        )}
                        inline={true}
                    >
                        <InputGroup
                            id="organisationSize"
                            fill="true"
                            placeholder="Number of effective employees"
                            value={organisationSize}
                            type="number"
                            disabled
                            rightElement={InputElementIcon(
                                `
                                The total number of direct employees, full-time, part-time, permanent and temporary,
                                as part of your PAYE scheme. Also to include the total number of labour-only
                                subcontractors. Labour-only subcontractors are typically considered third-party
                                works who have worked under your supervision, using materials, tools and equipment
                                that you have provided, and would typically be covered by your insurance policies.
                            `,
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Organisation Type"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill required',
                            organisationType
                        )}
                        labelFor="organisationType"
                    >
                        <HTMLSelect
                            id="organisationType"
                            fill={true}
                            defaultValue={organisationType}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            options={ORGANISATION_TYPES}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Minimum Value of Contracts/Works Undertaken (£)"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            minWorkValue
                        )}
                        labelFor="minWorkValue"
                    >
                        <CurrencyInput
                            id="minWorkValue"
                            allowNegativeValue={false}
                            name="minWorkValue"
                            className="bp3-input"
                            placeholder="Minimum Value (£)"
                            maxLength={10}
                            value={minWorkValue}
                            decimalsLimit={2}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                this.setState({
                                    form: Object.assign({}, form, {
                                        minWorkValue: value ?? null,
                                    }),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Maximum Value of Contracts/Works Undertaken (£)"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            maxWorkValue
                        )}
                        labelFor="maxWorkValue"
                    >
                        <CurrencyInput
                            id="maxWorkValue"
                            allowNegativeValue={false}
                            name="maxWorkValue"
                            className="bp3-input"
                            placeholder="Minimum Value (£)"
                            maxLength={10}
                            value={maxWorkValue}
                            decimalsLimit={2}
                            disableAbbreviations={true}
                            step={1}
                            prefix="£"
                            onValueChange={(value) =>
                                this.setState({
                                    form: Object.assign({}, form, {
                                        maxWorkValue: value ?? null,
                                    }),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Operate 24 Hours ?"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            is24hour
                        )}
                        labelFor="is24hour"
                    >
                        <HTMLSelect
                            id="is24hour"
                            fill={true}
                            defaultValue={is24hour}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            options={OPTIONAL_YES_NO_OPTIONS}
                        />
                    </FormGroup>
                    <FormGroup
                        label="To Deliver Your Services Do You Attend Client Sites ?"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill required',
                            isServiceBySiteVisit
                        )}
                        labelFor="isServiceBySiteVisit"
                    >
                        <HTMLSelect
                            id="isServiceBySiteVisit"
                            fill={true}
                            defaultValue={isServiceBySiteVisit}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            options={YES_NO_OPTIONS}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Standard Payment Terms (Days)"
                        inline={true}
                        className={this.getHighlightClass(
                            'form-fill',
                            standardPaymentDays
                        )}
                        labelFor="standardPaymentDays"
                    >
                        <InputGroup
                            id="standardPaymentDays"
                            fill={true}
                            placeholder="Standard Payment Terms (Days)"
                            value={standardPaymentDays}
                            type="number"
                            min="1"
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Unique Tax Reference Number"
                        inline={true}
                        className="form-fill"
                        labelFor="uniqueTaxReferenceNumber"
                    >
                        <InputGroup
                            id="uniqueTaxReferenceNumber"
                            fill={true}
                            placeholder="Unique Tax Reference Number"
                            value={form.uniqueTaxReferenceNumber}
                            minLength={10}
                            maxLength={10}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            rightElement={InputElementIcon(
                                'Enter if applicable',
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="CIS Number"
                        inline={true}
                        className="form-fill"
                        labelFor="cisNumber"
                    >
                        <InputGroup
                            id="cisNumber"
                            fill={true}
                            placeholder="CIS Number"
                            value={form.cisNumber}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            rightElement={InputElementIcon(
                                'Construction Industry Scheme (CIS) number, if applicable',
                                'info'
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="VAT Number"
                        inline={true}
                        className="form-fill"
                        labelFor="vatNumber"
                    >
                        <InputGroup
                            id="vatNumber"
                            fill={true}
                            placeholder="VAT Number"
                            value={form.vatNumber}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            rightElement={InputElementIcon(
                                'Enter if applicable',
                                'info'
                            )}
                        />
                    </FormGroup>

                    <FileSingleUpload
                        form={form}
                        fileId="logo"
                        isImage={true}
                        isMobile={isMobile}
                        fileLabel={logoLabel}
                        onUpdate={this.onUpdate}
                    ></FileSingleUpload>

                    <FormGroup
                        label="State"
                        inline={true}
                        className="form-fill"
                        labelFor="isActive"
                    >
                        <HTMLSelect
                            id="isActive"
                            fill={true}
                            defaultValue={form.isActive.toString()}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                            options={ACTIVE_OPTIONS}
                        />
                    </FormGroup>

                    <H2 className="clearfix">Contacts</H2>

                    <H3 className="clearfix">Primary Contact</H3>

                    <FormGroup
                        label="First Name"
                        inline={true}
                        className="form-fill"
                        labelFor="primaryContactFirstName"
                    >
                        <InputGroup
                            id="primaryContactFirstName"
                            fill={true}
                            placeholder="First Name"
                            value={form.primaryContactFirstName}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Surname"
                        inline={true}
                        className="form-fill"
                        labelFor="primaryContactSurname"
                    >
                        <InputGroup
                            id="primaryContactSurname"
                            fill={true}
                            placeholder="Surname"
                            value={form.primaryContactSurname}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Email Address"
                        inline={true}
                        className="form-fill"
                        labelFor="primaryContactEmail"
                    >
                        <InputGroup
                            id="primaryContactEmail"
                            type="email"
                            fill={true}
                            placeholder="Email Address"
                            value={form.primaryContactEmail}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Phone Number"
                        inline={true}
                        className="form-fill"
                        labelFor="primaryContactPhoneNumber"
                    >
                        <InputGroup
                            id="primaryContactPhoneNumber"
                            type="phone"
                            fill={true}
                            placeholder="Phone Number"
                            value={form.primaryContactPhoneNumber}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>

                    <H2 className="clearfix">Registered Company Address</H2>

                    <FormGroup
                        label="Address Line 1"
                        inline={true}
                        className="form-fill required"
                        labelFor="addressLine1"
                    >
                        <InputGroup
                            id="addressLine1"
                            fill={true}
                            required
                            placeholder="Address Line 1"
                            value={form.addressLine1}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 2"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine2"
                    >
                        <InputGroup
                            id="addressLine2"
                            fill={true}
                            placeholder="Address Line 2"
                            value={form.addressLine2}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Address Line 3"
                        inline={true}
                        className="form-fill"
                        labelFor="addressLine3"
                    >
                        <InputGroup
                            id="addressLine3"
                            fill={true}
                            placeholder="Address Line 3"
                            value={form.addressLine3}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Town/City"
                        inline={true}
                        className="form-fill required"
                        labelFor="town"
                    >
                        <InputGroup
                            id="town"
                            fill={true}
                            required
                            placeholder="Town/City"
                            value={form.town}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="County/Region"
                        inline={true}
                        className="form-fill"
                        labelFor="county"
                    >
                        <InputGroup
                            id="county"
                            fill={true}
                            placeholder="County/Region"
                            value={form.county}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        label="Postal/Zip Code"
                        inline={true}
                        className="form-fill"
                        labelFor="postCode"
                    >
                        <InputGroup
                            id="postCode"
                            fill={true}
                            placeholder="Postal/Zip Code"
                            value={form.postCode}
                            onChange={(e) =>
                                this.setState({
                                    form: StateHandler.getStateObject(e, form),
                                })
                            }
                        />
                    </FormGroup>
                    <FormGroup
                        inline={true}
                        label="Country"
                        labelFor="country"
                        className="form-fill required"
                    >
                        <HTMLSelect
                            id="country"
                            required
                            fill={true}
                            options={COUNTRY_CODE_OPTIONS}
                            defaultValue={form.country}
                            onChange={(e) =>
                                this.setState({
                                    ...this.state,
                                    form: {
                                        ...this.state.form,
                                        country: e.target.value,
                                    },
                                })
                            }
                        />
                    </FormGroup>

                    <FormGroup>
                        <ButtonLink
                            type="button"
                            intent="default"
                            className="float-left"
                            to={Routing.getRouteUpOneLevel(history, match)}
                        >
                            <Icon icon="ban" />
                            Cancel
                        </ButtonLink>
                        <Button
                            type="submit"
                            intent="primary"
                            className="float-right"
                        >
                            <Icon icon="paper-plane" />
                            {isEditing ? 'Update' : 'Create'}
                        </Button>
                    </FormGroup>
                </form>
            </>
        );
    };

    render() {
        return (
            <Loading isLoading={this.state.isLoading}>
                <div className="page ServiceProviderAddEdit">
                    <div className="no-styling page-wrapper">
                        {this.getForm()}
                    </div>
                </div>
            </Loading>
        );
    }
}

export default withRouter(ServiceProviderAddEdit);
